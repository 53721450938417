import { useContext } from 'react';
import { NetworkContext } from '../../../../context/NetworkContext';
import {
  GET_PROGRESS_ACTIVITY_CODES,
  SEARCH_PROGRESS_ACTIVITY_TYPES,
  SEARCH_PROGRESS_CONDITION_TYPES,
  SEARCH_PROGRESS_INCIDENT_TYPES,
} from '../../../../graphql/queries/progress-activity-submissions';
import {
  GET_PROJECT_TEAMS,
  SEARCH_PROJECTS,
  SEARCH_PROJECT_LOCATIONS,
} from '../../../../graphql/queries/projects';
import { useIndexedDB } from '../../../../hooks/useIndexedDB';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';

export const useProgressDataRetrieval = (
  selectedProject: any,
  selectedScope: any
) => {
  const { useLazyQuery } = useOrganisationAwareApollo();
  const { putItem: updateLocalData } = useIndexedDB({
    objectStoreName: `cached_project_data`,
  });

  const { isOffline } = useContext(NetworkContext);

  const activityTypesLocalCacheId = `activityTypes_${selectedProject?.id}`;
  const projectLocationsLocalCacheId = `projectLocations_${selectedProject?.id}`;
  const conditionTypesLocalCacheId = `conditionTypes_${selectedProject?.id}`;
  const incidentTypesLocalCacheId = `incidentTypes_${selectedProject?.id}`;
  const projectsDataLocalCacheId = `project_${selectedProject?.id}`;
  const projectTeamsDataLocalCacheId = selectedScope?.id
    ? `project_teams_${selectedProject?.id}_${selectedScope?.id}`
    : `project_teams_${selectedProject?.id}`;
  const progressCodesDataLocalCacheId = `project_progress_codes_${selectedProject?.id}`;

  const [searchProgressActivityTypes, { loading: actTypeLoading }] =
    useLazyQuery(SEARCH_PROGRESS_ACTIVITY_TYPES, {
      fetchPolicy: 'network-only',
    });

  const [searchProjectLocations, { loading: locsLoading }] = useLazyQuery(
    SEARCH_PROJECT_LOCATIONS,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [searchProgressConditionTypes, { loading: conditionsLoading }] =
    useLazyQuery(SEARCH_PROGRESS_CONDITION_TYPES, {
      fetchPolicy: 'network-only',
    });

  const [searchProgressIncidentTypes, { loading: incidentsLoading }] =
    useLazyQuery(SEARCH_PROGRESS_INCIDENT_TYPES, {
      fetchPolicy: 'network-only',
    });

  const [fetchProjects, { loading: projectsLoading }] = useLazyQuery(
    SEARCH_PROJECTS,
    {
      variables: { input: { id: selectedProject?.id } },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchProjectTeams, { loading: teamsLoading }] = useLazyQuery(
    GET_PROJECT_TEAMS,
    {
      variables: {
        projectId: selectedProject?.id,
        ...(selectedScope?.id
          ? {
              scopeId: selectedScope?.id,
              shouldReturnAllTeamsIfNoTeamsOnScope: true,
            }
          : {}),
        input: { limit: 100 },
      },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchProjectProgressCodes, { loading: progressCodesLoading }] =
    useLazyQuery(GET_PROGRESS_ACTIVITY_CODES, {
      variables: { projectId: selectedProject?.id, input: { limit: 100 } },
      fetchPolicy: 'network-only',
    });

  const searchAndSyncActivityTypes = async () => {
    if (!isOffline) {
      const result = await searchProgressActivityTypes({
        variables: {
          searchTerm: '',
          projectId: selectedProject?.id,
          input: { limit: 600 },
        },
      });
      const activityTypeResults =
        result?.data?.searchProgressActivityTypes?.results || [];
      updateLocalData({ id: activityTypesLocalCacheId, activityTypeResults });
    }
  };

  const searchAndSyncLocations = async () => {
    if (!isOffline) {
      const result = await searchProjectLocations({
        variables: {
          searchTerm: '',
          projectId: selectedProject?.id,
          input: { limit: 600 },
        },
      });
      const locationResults =
        result?.data?.searchProjectLocations?.results || [];
      updateLocalData({ id: projectLocationsLocalCacheId, locationResults });
    }
  };

  const searchAndSyncConditionTypes = async () => {
    if (!isOffline) {
      const result = await searchProgressConditionTypes({
        variables: {
          searchTerm: '',
          projectId: selectedProject?.id,
          input: { limit: 600 },
        },
      });
      const conditionTypeResults =
        result?.data?.searchProgressConditionTypes?.results || [];
      updateLocalData({ id: conditionTypesLocalCacheId, conditionTypeResults });
    }
  };

  const searchAndSyncIncidentTypes = async () => {
    if (!isOffline) {
      const result = await searchProgressIncidentTypes({
        variables: {
          searchTerm: '',
          projectId: selectedProject?.id,
          input: { limit: 600 },
        },
      });
      const incidentTypeResults =
        result?.data?.searchProgressIncidentTypes?.results || [];
      updateLocalData({ id: incidentTypesLocalCacheId, incidentTypeResults });
    }
  };

  const fetchAndSyncProgressCodes = async () => {
    if (!isOffline) {
      const { data: progressCodesData } = await fetchProjectProgressCodes();
      const progressCodesResults =
        progressCodesData?.getProgressActivityCodes?.results?.map(
          (progressCode: any) => ({
            ...progressCode,
            projectId: selectedProject?.id,
          })
        ) || [];

      updateLocalData({
        id: progressCodesDataLocalCacheId,
        progressCodesResults,
      });
    }
  };

  const fetchProjectsAndTeamsFromServerAndSync = async () => {
    if (!isOffline) {
      const projects = await fetchProjects();
      const projectResults = projects?.data?.searchProjects?.results || [];
      updateLocalData({ id: projectsDataLocalCacheId, projectResults });

      const teams = await fetchProjectTeams();
      const teamsResults =
        teams?.data?.getProjectTeams?.results?.map((team: any) => ({
          ...team,
          projectId: selectedProject?.id,
        })) || [];
      updateLocalData({ id: projectTeamsDataLocalCacheId, teamsResults });
    }
  };

  const retrieveAndCacheDataFromServer = async () => {
    if (selectedProject?.id) {
      fetchProjectsAndTeamsFromServerAndSync();
      searchAndSyncActivityTypes();
      searchAndSyncLocations();
      searchAndSyncConditionTypes();
      searchAndSyncIncidentTypes();
      fetchAndSyncProgressCodes();
    }
  };

  return {
    retrieveAndCacheDataFromServer,
    isSyncing:
      projectsLoading ||
      teamsLoading ||
      locsLoading ||
      actTypeLoading ||
      conditionsLoading ||
      incidentsLoading ||
      progressCodesLoading,
    fetchProjectsAndTeamsFromServerAndSync,
    fetchAndSyncProgressCodes,
  };
};
