import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useContext, useEffect } from 'react';
import FormCard from '../../../components/FormCard/FormCard';
import {
  DELETE_ACTIVITY_CODE,
  SAVE_ACTIVITY_CODE,
} from '../../../graphql/mutations/activity-codes';
import { GET_ACTIVITY_CODES } from '../../../graphql/queries/activity-codes';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { useToast } from '../../../context/ToastContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';

export const SaveActivityCode = () => {
  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const [deleteActivityCode] = useMutation(DELETE_ACTIVITY_CODE);

  const { userProfile } = useContext(UserProfileContext);

  const hasDeletePermission = userProfile?.permissions?.includes(
    Permission.DeleteActivityCodes
  );
  let { id } = useParams();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_ACTIVITY_CODES, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  const handleDelete = async () => {
    try {
      await deleteActivityCode({
        variables: { id: activityCode.id },
      });

      addToast('Activity code deleted', 'success');
      navigate(`/finance/codes`);
    } catch (error) {
      console.log(error);
      addToast('Error deleting activity code', 'error');
    }
  };

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  const [activityCode] = data?.getActivityCodes?.results || [];

  const [saveActivityCode] = useMutation(SAVE_ACTIVITY_CODE);
  const rateConfig = {
    formSections: [
      {
        title: 'Activity Code',
        fields: [
          {
            title: 'Name',
            id: 'name',
            type: 'input',
          },
          {
            title: 'Code',
            id: 'code',
            type: 'input',
          },
          {
            title: 'Internal Id',
            id: 'internalId',
            type: 'input',
          },
          {
            title: 'Color',
            id: 'color',
            type: 'colour',
            description:
              'Select a colour from the above colour picker for this code.',
          },
          {
            title: 'Enabled',
            id: 'enabled',
            type: 'switch',
          },
        ],
      },
      {
        title: 'Special Configuration',
        fields: [
          {
            title: 'Use Employee / Subcontractor Default Rate',
            id: 'useResourceDefaultRate',
            type: 'switch',
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = id ? activityCode : { color: 'rgba(0,0,0,1)' };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Code is required'),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveActivityCode({
        variables: {
          id: values?.id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });

      if (data?.saveActivityCode?.success) {
        addToast('Activity code saved', 'success');
        setSubmitting(false);
        navigate(`/finance/codes`);
      } else {
        if (data?.saveActivityCode?.message.includes('already exists')) {
          addToast(
            `Error saving activity code. Code "${values.code}" already exists.`,
            'error'
          );
        } else {
          addToast('Error saving activity code', 'error');
        }
      }
    } catch (error) {
      console.error(error);
      addToast('Error saving activity code', 'error');
    }
  };

  return (
    <>
      <FormCard
        key={`${rateConfig?.title}`}
        config={rateConfig}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        entityName="Activity Code"
        onDelete={isUpdate && hasDeletePermission ? handleDelete : undefined}
      />
    </>
  );
};
