import { gql } from '@apollo/client';

export const GET_PROJECTS_SUMMARY = gql`
  query getProjects(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectStatus: String
  ) {
    getProjects(
      input: $input
      activeOrganisation: $activeOrganisation
      projectStatus: $projectStatus
    ) {
      count
      results {
        id
        name
        colour
        internalId
        status
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_PROJECTS_COUNT = gql`
  query getProjects($input: GenericFindOptions, $activeOrganisation: String!) {
    getProjects(input: $input, activeOrganisation: $activeOrganisation) {
      count
    }
  }
`;

export const GET_PROJECT = gql`
  query getProjectDetails(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getProjectDetails(input: $input, activeOrganisation: $activeOrganisation) {
      id
      name
      colour
      description
      defaultCurrency
      projectValue
      startDate
      endDate
      enabled
      internalId
      status
      createdAt
      updatedAt
      customData {
        key
        value
      }
      managers {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjects($input: GenericFindOptions, $activeOrganisation: String!) {
    getProjects(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        description
        defaultCurrency
        projectValue
        startDate
        endDate
        enabled
        internalId
        status
        createdAt
        updatedAt
        customData {
          key
          value
        }
      }
    }
  }
`;

export const SEARCH_PROJECTS = gql`
  query searchProjects(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String
  ) {
    searchProjects(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        description
        defaultCurrency
        projectValue
        startDate
        endDate
        enabled
        internalId
        status
        createdAt
        updatedAt
        customData {
          key
          value
        }
      }
    }
  }
`;

export const SEARCH_PROJECT_LOCATIONS = gql`
  query searchProjectLocations(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: String
    $searchTerm: String!
  ) {
    searchProjectLocations(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
      projectId: $projectId
    ) {
      count
      results {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_PROJECT_OUTCOMES = gql`
  query searchProjectOutcomes(
    $input: GenericFindOptions
    $projectId: String!
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchProjectOutcomes(
      input: $input
      projectId: $projectId
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_PROJECT_OUTCOMES_DETAILED = gql`
  query searchProjectOutcomes(
    $input: GenericFindOptions
    $projectId: String!
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchProjectOutcomes(
      input: $input
      projectId: $projectId
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        configuration {
          id
          name
          locations {
            id
            name
            latitude
            longitude
          }
          phases {
            id
            name
            locations {
              id
              name
              latitude
              longitude
            }
            activityGroups {
              id
              name
              originId
              locations {
                id
                name
                latitude
                longitude
              }
              timings {
                hoursEstimate
                personHoursEstimate
                appliesToSpecificLocations
                locations {
                  id
                  name
                  latitude
                  longitude
                }
              }
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_TEAMS = gql`
  query getProjectTeams(
    $input: GenericFindOptions
    $projectId: ID!
    $scopeId: ID
    $shouldReturnAllTeamsIfNoTeamsOnScope: Boolean
    $activeOrganisation: String!
  ) {
    getProjectTeams(
      input: $input
      projectId: $projectId
      scopeId: $scopeId
      shouldReturnAllTeamsIfNoTeamsOnScope: $shouldReturnAllTeamsIfNoTeamsOnScope
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        createdAt
        updatedAt
        ptar {
          id
          enabled
          assignment_role {
            id
            role {
              id
              name
            }
            assignment {
              id
              lrs {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_MANAGERS = gql`
  query getProjectManagers(
    $input: GenericFindOptions
    $projectId: String
    $activeOrganisation: String!
  ) {
    getProjectManagers(
      input: $input
      projectId: $projectId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        projectId
        managers {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PROJECT_LOCATIONS = gql`
  query getProjectLocations(
    $input: GenericFindOptions
    $projectId: ID!
    $activeOrganisation: String!
  ) {
    getProjectLocations(
      input: $input
      projectId: $projectId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        latitude
        longitude
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_LOCATION_ASSETS = gql`
  query getProjectLocationAssets(
    $input: GenericFindOptions
    $projectId: String
    $projectLocationId: String
    $activeOrganisation: String!
  ) {
    getProjectLocationAssets(
      input: $input
      projectId: $projectId
      projectLocationId: $projectLocationId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        category
        latitude
        longitude
        projectLocationId
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_ACTIVITY_GROUPS = gql`
  query getProjectActivityGroups(
    $input: GenericFindOptions
    $projectId: ID!
    $activeOrganisation: String!
  ) {
    getProjectActivityGroups(
      input: $input
      projectId: $projectId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        pagtm {
          pagtmat {
            id
            name
            pag {
              id
              name
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_PROJECT_ACTIVITY_GROUPS = gql`
  query searchProjectActivityGroups(
    $input: GenericFindOptions
    $projectId: String!
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchProjectActivityGroups(
      input: $input
      projectId: $projectId
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_OUTCOMES = gql`
  query getProjectOutcomes(
    $input: GenericFindOptions
    $projectId: ID!
    $activeOrganisation: String!
  ) {
    getProjectOutcomes(
      input: $input
      projectId: $projectId
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        teams
        configuration {
          id
          name
          locations {
            id
            name
            latitude
            longitude
          }
          locationGroups {
            id
            name
            locations {
              id
              name
              latitude
              longitude
            }
          }
          phases {
            id
            name
            locations {
              id
              name
              latitude
              longitude
            }
            activityGroups {
              id
              name
              originId
              locations {
                id
                name
                latitude
                longitude
              }
              timings {
                hoursEstimate
                personHoursEstimate
                appliesToSpecificLocations
                locations {
                  id
                  name
                  latitude
                  longitude
                }
                useLocationGroups
                locationTimingGroups {
                  id
                  name
                  hoursEstimate
                  personHoursEstimate
                  locationGroupId
                }
              }
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PROJECT_ACTIVITY_TYPES = gql`
  query getProjectActivityTypes(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: String
    $onlyUnused: Boolean
  ) {
    getProjectActivityTypes(
      input: $input
      activeOrganisation: $activeOrganisation
      projectId: $projectId
      onlyUnused: $onlyUnused
    ) {
      count
      results {
        id
        name
        progressActivityCategoryId
        defaultProgressCode {
          id
          code
        }
        pag {
          id
          name
        }
        pagtmat {
          pagtm {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MISSING_RATES_FOR_PROJECT = gql`
  query getMissingRatesForProject(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $projectId: String!
    $labourResourceType: String!
  ) {
    getMissingRatesForProject(
      input: $input
      activeOrganisation: $activeOrganisation
      projectId: $projectId
      labourResourceType: $labourResourceType
    ) {
      count
      results {
        id
        lrs {
          id
          type
          firstName
          lastName
          email
          updatedAt
        }
      }
    }
  }
`;
