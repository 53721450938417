import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../../components/Tables/tables/Table';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';

import moment from 'moment';
import { GET_PROGRESS_ACTIVITY_CODES } from '../../../graphql/queries/progress-activity-submissions';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Button } from '../../../components/Buttons/Button';
import NoData from '../../../components/NoData/NoData';
import LinkButton from '../../../components/Buttons/LinkButton';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { truncate } from '../../../utils/utils';

interface ProjectProgressCodeListProps {
  projectId?: string;
}

const headerMapping = {
  code: 'Code',
  description: 'Description',
  updatedAt: 'Last Updated',
} as Record<string, any>;

export const PaginatedProjectProgressCodeList = ({
  projectId,
}: ProjectProgressCodeListProps) => {
  const { userProfile } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const { useQuery } = useOrganisationAwareApollo();

  const { limit, offset, setOffset } = usePagination();

  const { data, loading } = useQuery(GET_PROGRESS_ACTIVITY_CODES, {
    variables: {
      projectId,
      input: { limit, offset },
    },
    fetchPolicy: 'network-only',
  });

  const count = data?.getProgressActivityCodes?.count;
  const progressCodes = data?.getProgressActivityCodes?.results;

  const totalPages = Math.ceil(count / limit);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  return (
    <div>
      <>
        {userProfile?.permissions?.includes(Permission.UpdateProject) ? (
          <div className="w-full flex justify-between px-4 py-2 gap-x-2">
            <p className="text-sm px-4 text-gray-600">
              Progress codes can be used to group activities together.
            </p>
            <Button
              onClick={() =>
                navigate(`/projects/${projectId}/progress-codes/new`)
              }
              type="submit"
              text="New Progress Code"
            />
          </div>
        ) : null}

        <Table
          currentPage={Math.floor(offset / limit) + 1}
          totalPages={totalPages}
          itemsPerPage={limit}
          totalResults={count || 0}
          onClickRow={(id: any) => {
            if (userProfile?.permissions?.includes(Permission.UpdateProject)) {
              navigate(`/projects/${projectId}/progress-codes/${id}`);
            }
          }}
          headerMapper={(text: string) => headerMapping[text]}
          NoData={() => (
            <NoData loading={loading}>
              It looks like there are no progress codes for this project.
              <LinkButton
                to={`/projects/${projectId}/progress-codes/new`}
                target="_blank"
              >
                Add a Progress Code
              </LinkButton>
            </NoData>
          )}
          data={progressCodes?.map((code: any) => ({
            id: code.id,
            code: code.code,
            description: truncate(code?.description, 100),
            updatedAt: moment.unix(parseInt(code.updatedAt) / 1000).fromNow(),
          }))}
          onPageChange={handlePageChange}
        />
      </>
    </div>
  );
};

export const ProjectProgressCodeList = ({
  projectId,
}: ProjectProgressCodeListProps) => (
  <PaginationProvider>
    <PaginatedProjectProgressCodeList projectId={projectId} />
  </PaginationProvider>
);
