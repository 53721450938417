import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';
import { SAVE_NEXT_OF_KIN } from '../../../graphql/mutations/next-of-kin';

import { GET_NEXT_OF_KIN_DETAILS } from '../../../graphql/queries/profiles';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { LabourHeading } from '../heading/labour';
import { useToast } from '../../../context/ToastContext';

export const SaveNextOfKin = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const [fetchNextOfKins, { data }] = useLazyQuery(GET_NEXT_OF_KIN_DETAILS, {
    variables: { labourResourceId: id },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchNextOfKins({ variables: { labourResourceId: id } });
  }, [id, isUpdate]);

  const [nextofkin] = data?.getNextOfKinDetails?.results || [];

  const [saveNextOfKin] = useMutation(SAVE_NEXT_OF_KIN);

  const initialValues = id
    ? {
        ...nextofkin,
      }
    : {};

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveNextOfKin({
        variables: {
          id,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (data?.saveNextOfKin.success) {
        addToast('Next of Kin saved successfully', 'success');
        navigate(-1);
      } else {
        addToast('Error saving Next of Kin', 'error');
      }
    } catch (error) {
      addToast('Error saving Next of Kin', 'error');
      console.error(error);
    }
  };

  const nextofkinConfig = {
    formSections: [
      {
        title: 'Next of Kin',
        description: 'Basic details about the Next Of Kin',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Next Of Kin Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'relationship',
            type: 'input',
            title: 'Relationship',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'address',
            type: 'textarea',
            title: 'Address',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'contactNumber',
            type: 'input',
            title: 'Contact Number',
            inputTransformer: (text: string) => text,
          },
        ],
      },
    ],
  } as FormConfig;

  const NextOfKinCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    relationship: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    address: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    contactNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  return (
    <>
      <LabourHeading
        id={id}
        labourType={
          location.pathname.includes('subcontractors')
            ? 'subcontractors'
            : 'employees'
        }
      />
      <FormCard
        key={`${nextofkinConfig?.title}`}
        config={nextofkinConfig}
        validationSchema={NextOfKinCreationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
