import {
  ChevronLeftIcon,
  ChevronRightIcon,
  BarsArrowUpIcon,
  BarsArrowDownIcon,
  Cog8ToothIcon,
} from '@heroicons/react/20/solid';
// @ts-ignore
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import DropDown from '../../Inputs/Dropdown';
import TextInput from '../../Inputs/TextInput';
import { Button } from '../../Buttons/Button';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { LoadingSpinner } from '../../Loading/LoadingSpinner';

interface ExportButtonProps {
  title: string;
  fetchPageOfDataForExport: (limit: number, offset: number) => Promise<any[]>;
  totalPages: number;
  itemsPerPage: number;
}

const ExportButton: React.FC<ExportButtonProps> = ({
  title,
  fetchPageOfDataForExport,
  totalPages,
  itemsPerPage,
}) => {
  const [exporting, setExporting] = useState(false);

  const handleExport = async () => {
    setExporting(true);

    let allData: any[] = [];

    for (let currentPage = 0; currentPage < totalPages; currentPage++) {
      const offset = currentPage * itemsPerPage;
      const data = (await fetchPageOfDataForExport(itemsPerPage, offset)) ?? [];
      allData = [...allData, ...data];
    }

    const csvContent = convertToCSV(allData);
    downloadCSV(csvContent);

    setExporting(false);
  };

  const convertToCSV = (data: any[]): string => {
    if (data.length === 0) return '';

    const headers = Object.keys(data[0]);
    const csvRows = data.map((row) =>
      headers.map((fieldName) => JSON.stringify(row[fieldName] || '')).join(',')
    );

    return [headers.join(','), ...csvRows].join('\r\n');
  };

  const downloadCSV = (csvContent: string) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'exported_data.csv');
  };

  return (
    <Button
      title={title}
      onClick={handleExport}
      isDisabled={exporting}
      text={
        exporting ? <LoadingSpinner /> : <ArrowDownTrayIcon className="w-5" />
      }
    />
  );
};

type HeaderMapperMetaData = {
  text: string;
  sorted?: boolean;
  sortDirection?: string;
  onClick?: () => void;
};

interface Props {
  data: any[];
  NoData?: React.FC;
  totalResults?: number;
  currentPage?: number;
  itemsPerPage?: number;
  totalPages?: number;
  showPagination?: boolean;
  columnsToFilter?: string[];
  headerMapper?: (key: string) => string | HeaderMapperMetaData;
  columnValueMapper?: { [key: string]: (text: string) => string };
  onPageChange?: (page: number) => void;
  onClickRow?: (id: number | string) => void;
  RowComponent?: React.FC<TableRowComponentProps> | null;
  showFilterOptions?: boolean;
  showSettingsButton?: boolean;
  tabs?: { name: string; value: any }[];
  setActiveTab?: (value: any) => void;
  activeTab?: any;
  onSettingsClick?: () => void;
  filterText?: string;
  setFilterText?: (value: string) => void;
  selectedFilterDimension?: string;
  setSelectedFilterDimension?: (value: string) => void;
  filterDimensions?: {
    id: string;
    name: string;
  }[];
  fetchPageOfDataForExport?: (limit: number, offset: number) => Promise<any>;
}

export interface TableRowComponentProps {
  id?: string;
  rowData?: Record<string, any>;
}

const renderPagination = ({
  data,
  currentPage,
  itemsPerPage,
  totalResults,
  totalPages,
  handlePageChange,
  renderPageNumbers,
}: any) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return data.length > 0 ? (
    <div className="flex flex-col md:flex-row items-center justify-between py-2 w-full pb-2 pt-4 px-4 gap-x-4 gap-y-2">
      <div>
        {totalResults ? (
          <p className="text-xs text-gray-700">
            Showing {(currentPage - 1) * itemsPerPage + 1} to{' '}
            {Math.min(currentPage * itemsPerPage, data.length)} of{' '}
            {totalResults} results
          </p>
        ) : null}
      </div>
      {totalPages ? (
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage <= 1}
            aria-label="Previous"
            className="relative inline-flex items-center px-2 py-2 disabled:bg-gray-100 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 rounded-l-md"
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>

          {renderPageNumbers({ currentPage, handlePageChange, totalPages })}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages}
            aria-label="Next"
            className="relative inline-flex items-center px-2 py-2 disabled:bg-gray-100 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 rounded-r-md"
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </nav>
      ) : null}
    </div>
  ) : null;
};

const renderPageButton = (
  pageNumber: number,
  handlePageChange: any,
  currentPage: any
) => (
  <button
    key={pageNumber}
    onClick={() => handlePageChange(pageNumber)}
    className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
      currentPage === pageNumber
        ? 'bg-green-500 text-white'
        : 'text-gray-700 bg-white hover:bg-gray-200'
    }`}
  >
    {pageNumber}
  </button>
);

const Ellipse = () => (
  <span
    key="ellipsis1"
    className="relative inline-flex items-center w-10 justify-center px-2 py-2 text-gray-500 bg-gray-100 border border-gray-300"
  >
    ...
  </span>
);

const renderTableData = ({
  data,
  columnsToFilter,
  columnValueMapper,
  RowComponent,
  onClickRow,
  mapHeader,
}: any) => {
  return data.map((rowData: any, index: any) => {
    const filteredRowData = Object.keys(rowData).reduce(
      (result: Record<string, any>, key) => {
        if (!columnsToFilter.includes(key)) {
          result[key] =
            columnValueMapper && columnValueMapper?.[key]
              ? columnValueMapper?.[key](rowData[key])
              : rowData[key];
        } else {
          delete result[key];
        }
        return result;
      },
      {}
    );
    return (
      <>
        {RowComponent && rowData?.id ? (
          <RowComponent
            key={`${rowData?.id}_rowComponent`}
            id={rowData?.id}
            rowData={rowData}
          />
        ) : (
          <tr
            onClick={() => onClickRow(rowData?.id)}
            key={`${rowData?.id}_table_row`}
            className={`border-b border-gray-200 hover:bg-gray-100 flex justify-center flex-col md:table-row`}
          >
            {Object.entries(filteredRowData).map(([key, value], index) => {
              const { text: headerText } = mapHeader(key);
              return headerText ? (
                <td
                  key={`${rowData?.id}_${index}`}
                  className="py-3 px-6 text-sm text-left text-black md:whitespace-nowrap"
                >
                  <p className="flex flex-col justify-center gap-y-1 gap-x-2">
                    <b className="md:hidden">{headerText}: </b>
                    <div>{value}</div>
                  </p>
                </td>
              ) : null;
            })}
          </tr>
        )}
      </>
    );
  });
};

const renderPageNumbers = ({
  currentPage,
  handlePageChange,
  totalPages,
}: any) => {
  let pages = [];

  const pageRange = 3;
  const halfRange = Math.floor(pageRange / 2);

  let startPage = Math.max(1, currentPage - halfRange);
  let endPage = Math.min(startPage + pageRange - 1, totalPages);

  if (endPage - startPage < pageRange - 1) {
    startPage = Math.max(1, endPage - pageRange + 1);
  }

  if (startPage > 1) {
    pages.push(renderPageButton(1, handlePageChange, currentPage));
    if (startPage > 2) {
      pages.push(<Ellipse />);
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(renderPageButton(i, handlePageChange, currentPage));
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pages.push(<Ellipse />);
    }
    pages.push(renderPageButton(totalPages, handlePageChange, currentPage));
  }

  return pages;
};

export const Table: React.FC<Props> = ({
  data = [],
  NoData,
  currentPage,
  totalResults,
  itemsPerPage,
  totalPages,
  columnsToFilter = ['id'],
  headerMapper = (text: string) => text,
  columnValueMapper,
  showPagination = true,
  onPageChange = () => {},
  onClickRow = () => {},
  RowComponent = null,
  filterText,
  setFilterText = () => {},
  showFilterOptions = false,
  showSettingsButton = false,
  onSettingsClick,
  tabs,
  setActiveTab = () => {},
  activeTab,
  selectedFilterDimension,
  fetchPageOfDataForExport,
  setSelectedFilterDimension = () => {},
  filterDimensions = [],
}: Props) => {
  const handlePageChange = (pageNumber: number) => {
    onPageChange(pageNumber);
  };
  console.log('selectedFilterDimension', selectedFilterDimension);
  console.log('filterDimensions', filterDimensions);

  const mapHeader = (key: string): HeaderMapperMetaData => {
    const mappedHeader = headerMapper(key);

    const isString = typeof mappedHeader === 'string';

    if (isString || !mappedHeader) {
      return { text: mappedHeader } as HeaderMapperMetaData;
    }

    return mappedHeader;
  };

  return (
    <div className="flex flex-col rounded-t-lg">
      <div className="shadow rounded-t-lg">
        {fetchPageOfDataForExport || showFilterOptions ? (
          <div className="bg-white px-4 py-2 gap-x-2 flex rounded-t-lg items-center">
            {fetchPageOfDataForExport ? (
              <div className="mt-2">
                <ExportButton
                  // @ts-ignore
                  fetchPageOfDataForExport={fetchPageOfDataForExport}
                  totalPages={totalPages ?? 0}
                  itemsPerPage={itemsPerPage ?? 0}
                  title="Download"
                />
              </div>
            ) : null}
            {showFilterOptions ? (
              <>
                <div className="w-full">
                  <TextInput
                    key="filter-selection"
                    question={{
                      id: 'Search',
                      placeholder: `Filter by ${
                        filterDimensions?.find(
                          (dimension) =>
                            dimension.id === selectedFilterDimension
                        )?.name
                      }`,
                    }}
                    handleChange={setFilterText}
                    value={filterText}
                  />
                </div>
                <div className="mt-2 w-52 flex justify-center">
                  <DropDown
                    key={'filter_dimensions'}
                    // @ts-ignore
                    question={{
                      options: filterDimensions?.map((opt: any) => ({
                        id: opt?.id,
                        name: opt?.name,
                        value: opt?.id,
                      })),
                    }}
                    handleChange={(selectedValue) => {
                      setSelectedFilterDimension(selectedValue);
                    }}
                    value={selectedFilterDimension}
                  />
                </div>
                {showSettingsButton ? (
                  <div className="pt-2">
                    <Button onClick={onSettingsClick} className="bg-green-500">
                      <div className="h-5 w-5">
                        <Cog8ToothIcon />
                      </div>
                    </Button>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        ) : null}
        {tabs ? (
          <div className="flex flex-col md:flex-row w-full p-2 bg-white">
            {tabs?.map(({ name, value }) => (
              <div className="justify-center flex items-center">
                <Button
                  className={`${
                    activeTab === value
                      ? 'bg-black text-white hover:bg-black hover:text-white'
                      : 'bg-transparent text-black hover:bg-transparent hover:text-black'
                  } rounded-none justify-center flex items-center`}
                  text={name}
                  onClick={() => setActiveTab(value)}
                />
              </div>
            ))}
          </div>
        ) : null}
        <table className="table-auto w-full bg-white rounded-t-md">
          <thead className="rounded-t-lg">
            <tr className="bg-gray-800 rounded-t-lg border-t text-xs text-white hidden md:table-row">
              {!RowComponent && data?.[0]
                ? Object.keys(data?.[0])
                    .filter((key: string) => !columnsToFilter.includes(key))
                    .map((key, index) => {
                      const { text, sorted, sortDirection, onClick } =
                        mapHeader(key);

                      return text ? (
                        <th
                          key={index}
                          onClick={onClick}
                          className={`px-6 py-2 text-left ${
                            onClick ? 'cursor-pointer' : ''
                          }`}
                        >
                          <div className="flex">
                            {text}
                            {sorted ? (
                              <div className="pl-3">
                                {sortDirection === 'ASC' ? (
                                  <BarsArrowUpIcon className="h-5 w-5" />
                                ) : (
                                  <BarsArrowDownIcon className="h-5 w-5" />
                                )}
                              </div>
                            ) : null}
                          </div>
                        </th>
                      ) : null;
                    })
                : null}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && NoData ? (
              <NoData />
            ) : (
              renderTableData({
                RowComponent,
                data,
                onClickRow,
                columnValueMapper,
                columnsToFilter,
                mapHeader,
              })
            )}
          </tbody>
        </table>
      </div>
      {showPagination
        ? renderPagination({
            totalPages,
            data,
            currentPage,
            itemsPerPage,
            totalResults,
            handlePageChange,
            renderPageNumbers,
          })
        : null}
    </div>
  );
};
