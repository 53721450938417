import { gql } from '@apollo/client';

export const GET_TIMESHEET_CONFIGURATIONS_SUMMARY = gql`
  query getTimesheetConfigurations(
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getTimesheetConfigurations(
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        permittedGroups
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_TIMESHEET_CONFIGURATIONS = gql`
  query getTimesheetConfigurations(
    $submissionId: String
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    getTimesheetConfigurations(
      submissionId: $submissionId
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        type
        description
        granularity
        notifierEmail
        assignOnBehalf
        multiProject
        trackOwnTime
        activityCodes
        permittedGroups
        approvalGroups
        customDataToApply
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;
