import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  useIsLargeScreenOrSmaller,
  useIsMediumScreenOrSmaller,
  useIsSmallScreenOrSmaller,
  useIsXlScreenOrSmaller,
  useIsXxlScreenOrSmaller,
} from '../../hooks/useMediaQuery';
import { Button } from '../Buttons/Button';
import DropdownButton from '../DropdownButton/DropdownButton';
import NotificationIcon from '../Notification/NotificationIcon';
import { twMerge } from 'tailwind-merge';

export function Tabs({
  activeTab,
  tabs,
  notifications = {},
  setActiveTab,
}: {
  activeTab: string;
  tabs: string[];
  notifications?: Record<string, boolean>;
  setActiveTab: (tab: string) => void;
}) {
  const isSmall = useIsSmallScreenOrSmaller();
  const isMedium = useIsMediumScreenOrSmaller();
  const isLarge = useIsLargeScreenOrSmaller();
  const isXl = useIsXlScreenOrSmaller();
  const isXxl = useIsXxlScreenOrSmaller();

  const maxTabs = isSmall
    ? 0
    : isMedium
    ? 5
    : isLarge
    ? 7
    : isXl
    ? 9
    : isXxl
    ? 11
    : tabs.length;

  const displayedTabs = !maxTabs
    ? []
    : tabs.length > maxTabs
    ? tabs.slice(0, maxTabs - 1)
    : tabs;

  const dropdownTabs =
    tabs.length > maxTabs
      ? tabs.slice(maxTabs > 0 ? maxTabs - 1 : maxTabs, tabs.length)
      : [];

  return (
    <>
      <div className="w-full px-2 bg-gray-800 py-2 flex flex-row">
        {displayedTabs.map((tab) => (
          <div
            className={`${
              activeTab === tab ? 'bg-black' : ''
            } justify-center flex items-center`}
          >
            <Button
              style={{ borderRadius: 0, backgroundColor: 'transparent' }}
              onClick={() => {
                setActiveTab(tab);
              }}
              className={notifications[tab] ? 'flex relative gap-1' : ''}
            >
              {tab}
              {notifications[tab] ? <NotificationIcon /> : null}
            </Button>
          </div>
        ))}
        {dropdownTabs.length ? (
          <div
            className={`w-full sm:w-auto justify-end sm:justify-start flex items-center`}
          >
            <DropdownButton
              className={`rounded-none bg-transparent flex ${
                dropdownTabs.includes(activeTab) && !isSmall ? 'bg-black' : ''
              }`}
              items={dropdownTabs.map((tab) => ({
                text: (
                  <>
                    {tab}
                    {notifications[tab] ? (
                      <NotificationIcon className="ml-1" />
                    ) : null}
                  </>
                ),
                onClick: () => setActiveTab(tab),
                className: twMerge(
                  activeTab === tab ? 'bg-black' : '',
                  notifications[tab] ? 'flex relative gap-y-2' : ''
                ),
              }))}
            >
              {isSmall ? (
                <div className="HAMBURGER-ICON space-y-2">
                  <span className="block h-0.5 w-8 bg-white" />
                  <span className="block h-0.5 w-8 bg-white" />
                  <span className="block h-0.5 w-8 bg-white" />
                </div>
              ) : (
                <div className={`flex flex-row gap-x-1`}>
                  <span>More</span>
                  <ChevronDownIcon
                    data-testid={'chevron-down'}
                    className="h-4 w-4 text-white self-center"
                    aria-hidden="true"
                  />
                  {dropdownTabs.filter((tab) => notifications[tab]).length >
                  0 ? (
                    <NotificationIcon />
                  ) : null}
                </div>
              )}
            </DropdownButton>
          </div>
        ) : null}
      </div>
    </>
  );
}
