import { gql } from '@apollo/client';

export const GET_COMPANY_PROFILES_SUMMARY = gql`
  query getCompanyProfiles(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $labourResourceId: ID
  ) {
    getCompanyProfiles(
      input: $input
      activeOrganisation: $activeOrganisation
      labourResourceId: $labourResourceId
    ) {
      count
      results {
        id
        companyName
        companyRegNumber
        updatedAt
      }
    }
  }
`;

export const SEARCH_COMPANIES = gql`
  query searchCompanies(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $searchTerm: String!
  ) {
    searchCompanies(
      input: $input
      activeOrganisation: $activeOrganisation
      searchTerm: $searchTerm
    ) {
      count
      results {
        id
        companyName
      }
    }
  }
`;

export const GET_COMPANY_PROFILES = gql`
  query getCompanyProfiles(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $labourResourceId: ID
    $ownerStatus: String
  ) {
    getCompanyProfiles(
      input: $input
      activeOrganisation: $activeOrganisation
      labourResourceId: $labourResourceId
      ownerStatus: $ownerStatus
    ) {
      count
      results {
        id
        companyName
        companyRegNumber
        companyPhoneNumber
        vatRegistrationNumber
        companyAddress
        contactName
        companyEmail
        companyType
        profileId
        owner {
          id
          firstName
          lastName
          type
          email
        }
        hasAssignees
        missingOrInvalidCertifications {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_LABOUR_ASSIGNED_TO_COMPANY = gql`
  query getLabourAssignedToCompany(
    $activeOrganisation: String!
    $companyId: ID!
    $input: GenericFindOptions
  ) {
    getLabourAssignedToCompany(
      activeOrganisation: $activeOrganisation
      companyId: $companyId
      input: $input
    ) {
      count
      results {
        id
        firstName
        lastName
        email
        type
        updatedAt
      }
    }
  }
`;

export const GET_LABOUR_ASSIGNABLE_TO_COMPANY = gql`
  query getLabourAssignableToCompany(
    $activeOrganisation: String!
    $companyId: ID!
    $input: GenericFindOptions
  ) {
    getLabourAssignableToCompany(
      activeOrganisation: $activeOrganisation
      companyId: $companyId
      input: $input
    ) {
      count
      results {
        id
        firstName
        lastName
        email
        type
        updatedAt
        company {
          companyName
        }
      }
    }
  }
`;

export const GET_PROJECT_COMPANIES = gql`
  query getProjectCompanies(
    $activeOrganisation: String!
    $companyId: ID
    $projectId: ID
    $input: GenericFindOptions
  ) {
    getProjectCompanies(
      activeOrganisation: $activeOrganisation
      companyId: $companyId
      projectId: $projectId
      input: $input
    ) {
      count
      results {
        id
        enabled
        createdAt
        updatedAt
        company {
          id
          companyName
          companyRegNumber
          companyPhoneNumber
          vatRegistrationNumber
          companyAddress
          contactName
          companyEmail
          companyType
          profileId
          owner {
            id
            firstName
            lastName
            type
            email
          }
          missingOrInvalidCertifications {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_LABOUR_ASSIGNABLE_TO_PROJECT_FOR_COMPANY = gql`
  query getLabourAssignableToProjectForCompany(
    $activeOrganisation: String!
    $companyId: ID!
    $projectId: ID!
    $input: GenericFindOptions
  ) {
    getLabourAssignableToProjectForCompany(
      activeOrganisation: $activeOrganisation
      companyId: $companyId
      projectId: $projectId
      input: $input
    ) {
      count
      results {
        id
        firstName
        lastName
        email
        type
        updatedAt
      }
    }
  }
`;

export const GET_COMPANIES_WITH_MISSING_OR_INVALID_CERTIFICATIONS = gql`
  query getCompaniesWithMissingOrInvalidCertifications(
    $activeOrganisation: String!
    $projectId: ID!
    $filter: String
    $input: GenericFindOptions
  ) {
    getCompaniesWithMissingOrInvalidCertifications(
      activeOrganisation: $activeOrganisation
      projectId: $projectId
      filter: $filter
      input: $input
    ) {
      count
      results {
        id
        createdAt
        updatedAt
        company {
          id
          companyName
          companyRegNumber
          companyPhoneNumber
          vatRegistrationNumber
          companyAddress
          contactName
          companyEmail
          companyType
          profileId
          owner {
            id
            firstName
            lastName
            type
            email
          }
          missingOrInvalidCertifications {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const GET_COMPANIES_WITH_EXPIRING_CERTIFICATIONS = gql`
  query getCompaniesWithExpiringCertifications(
    $activeOrganisation: String!
    $certificationTypeId: ID!
    $projectId: ID
    $dateFilter: String!
    $input: GenericFindOptions
  ) {
    getCompaniesWithExpiringCertifications(
      activeOrganisation: $activeOrganisation
      certificationTypeId: $certificationTypeId
      projectId: $projectId
      dateFilter: $dateFilter
      input: $input
    ) {
      count
      results {
        id
        companyName
        companyRegNumber
        companyPhoneNumber
        vatRegistrationNumber
        companyAddress
        contactName
        companyEmail
        companyType
        profileId
        owner {
          id
          firstName
          lastName
          type
          email
        }
        certifications {
          id
          expiryDate
        }
        createdAt
        updatedAt
      }
    }
  }
`;
