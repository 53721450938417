import { gql } from '@apollo/client';

export const GET_ROLES_SUMMARY = gql`
  query getRoles(
    $input: GenericFindOptions
    $activeOrganisation: String!
    $includeArchivedRoles: Boolean
  ) {
    getRoles(
      input: $input
      activeOrganisation: $activeOrganisation
      includeArchivedRoles: $includeArchivedRoles
    ) {
      count
      results {
        id
        name
        description
        archivedAt
        enabled
        updatedAt
      }
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles($input: GenericFindOptions, $activeOrganisation: String!) {
    getRoles(input: $input, activeOrganisation: $activeOrganisation) {
      count
      results {
        id
        name
        description
        defaultRates {
          id
          currency
          key
          name
          rate
        }
        archivedAt
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_ROLES = gql`
  query searchRoles(
    $searchTerm: String!
    $input: GenericFindOptions
    $activeOrganisation: String!
  ) {
    searchRoles(
      searchTerm: $searchTerm
      input: $input
      activeOrganisation: $activeOrganisation
    ) {
      count
      results {
        id
        name
        description
        enabled
        updatedAt
      }
    }
  }
`;
