import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Currency } from '../../../types/Currency';
import { ProjectAssignmentList } from '../../Projects/assignments/list';
import { NextOfKinDetail } from '../nextOfKin/profile';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { SkillDetail } from '../skills/profile';
import { CertificationDetail } from '../certifications/profile';
import Avatar from 'react-avatar';
import { GET_CUSTOM_FIELDS } from '../../../graphql/queries/custom-data';
import { useEmployee } from '../../../hooks/api/useEmployees';
import CvExportList from '../cv/CvExportList';
import { useTabs } from '../../../hooks/useTabs';
import { Tabs } from '../../../components/Tabs/Tabs';

export const EmployeePage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery } = useOrganisationAwareApollo();

  const { userProfile } = useContext(UserProfileContext);

  const tabs = [
    'Summary',
    'Next of Kin',
    'Assignments',
    ...(userProfile?.permissions?.includes(Permission.ViewSkills)
      ? ['Skills']
      : []),
    'Certifications',
    'CV',
  ];

  const [activeTab, setActiveTab] = useTabs(tabs);

  const [fetchCustomFields, { data: customFieldsData }] = useLazyQuery(
    GET_CUSTOM_FIELDS,
    {
      variables: { labourResourceId: id, onlyEnabled: true },
      fetchPolicy: 'network-only',
    }
  );

  const [employee, { loading, error }] = useEmployee(id);

  const headerMapping = {
    currency: 'Currency',
    key: 'Key',
    rate: 'Rate',
    updatedAt: 'Updated At',
  } as Record<string, any>;

  const currencies = Object.entries(Currency).filter((e: any) => isNaN(e));

  const sections = [
    {
      title: 'Email',
      content: employee?.email || 'Not Configured',
    },
    {
      title: 'Internal Id',
      content: employee?.internalId ?? 'Empty',
    },
    {
      title: 'Job Title',
      content: employee?.jobTitle || 'Not Configured',
    },
    {
      title: 'Default Role',
      content: employee?.defaultRole.name || 'Not Configured',
    },
    {
      title: 'Personal Profile',
      content: employee?.personalProfile || 'Not Configured',
    },
    {
      title: 'Project Experience',
      content: employee?.projectExperience ? (
        <div
          dangerouslySetInnerHTML={{
            __html: employee?.projectExperience,
          }}
        />
      ) : (
        'Not Configured'
      ),
    },
    {
      title: 'Nationality',
      content: employee?.nationality ?? 'Empty',
    },
    {
      title: 'Country of Residence',
      content: employee?.countryOfResidence ?? 'Empty',
    },
    ...currencies.map(([key, currencySymbol]) => {
      const ratesForThisCurrency = employee?.defaultRate?.filter(
        (rate: any) => rate.currency === key
      );
      return {
        title: ratesForThisCurrency?.length > 0 ? `Default ${key} Rates` : null,
        content:
          ratesForThisCurrency?.length > 0 ? (
            <Table
              data={ratesForThisCurrency.map((rate: any) => ({
                key: rate.name,
                rate: `${currencySymbol}${new Intl.NumberFormat('en-GB').format(
                  rate.rate
                )}`,
              }))}
              headerMapper={(text: string) => headerMapping[text]}
              onClickRow={() => {}}
              onPageChange={() => {}}
              showPagination={false}
              totalPages={1}
              totalResults={1}
              currentPage={0}
              itemsPerPage={employee.defaultRate?.length}
            />
          ) : null,
      };
    }),
  ];

  const getOptionSetNameByValue = (customOptionId: any) => {
    const fieldGroups = customFieldsData?.getCustomFieldGroups?.results;
    if (!fieldGroups) {
      return null;
    }
    for (const fieldGroup of fieldGroups) {
      const foundOption = fieldGroup.custom_fields?.find(
        (option: any) => option.id === customOptionId
      );
      if (foundOption) {
        return foundOption.name;
      }
    }

    return null;
  };

  const customSections = (employee?.customData || [])
    .map((customItem: any) => ({
      title: getOptionSetNameByValue(customItem.key),
      content: customItem.value,
    }))
    ?.filter((item: any) => item.title);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  const tabHasNotification: Record<string, boolean> = {
    Certifications: employee?.missingOrInvalidCertifications?.length,
  };

  return (
    <div>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <Tabs
          activeTab={activeTab}
          notifications={tabHasNotification}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
        <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
          <div className="flex flex-col">
            <div className="flex gap-x-2">
              <Avatar
                name={`${employee?.firstName} ${employee?.lastName}`}
                size="35"
                round
              />
              <h1 className="text-3xl font-extrabold text-gray-900">
                {employee?.firstName} {employee?.lastName}
              </h1>
            </div>
            <p className="mt-4 text-sm text-gray-500">
              Created: {moment.unix(employee?.createdAt / 1000).fromNow()}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Updated: {moment.unix(employee?.updatedAt / 1000).fromNow()}
            </p>
          </div>
          {userProfile?.permissions.includes(
            Permission.UpdateLabourProfiles
          ) ? (
            <div className="mt-2 md:mt-0">
              {['Skills', 'Certifications', 'CV'].includes(activeTab) ? null : (
                <Button
                  onClick={() => {
                    if (activeTab === 'Summary') {
                      navigate(`/labour/employees/${id}/edit`);
                    }
                    if (activeTab === 'Next of Kin') {
                      navigate(`/labour/employees/${id}/nextOfKin/edit`);
                    }
                  }}
                  type="submit"
                  text="Edit"
                />
              )}
            </div>
          ) : null}
        </div>

        {loading && (
          <div className="h-64 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        )}
        {error && (
          <p className="px-4 py-5 sm:p-6 text-red-500">
            Error loading data. Please try again.
          </p>
        )}
        {activeTab === 'Summary' ? (
          employee ? (
            <div className="border-t border-gray-200">
              {sections.map((section, index) =>
                section.title || section.content ? (
                  <div key={index} className="px-4 py-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      {section.title}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      {section.title === 'Personal Profile' ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: section.content,
                          }}
                        />
                      ) : (
                        section.content
                      )}
                    </p>
                  </div>
                ) : null
              )}
              {customSections.map((section: any, index: number) => (
                <div key={`custom-${index}`} className="px-4 py-4">
                  <h2 className="text-lg font-medium text-gray-900">
                    {section.title}
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    {section.content}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p>No employee data available</p>
          )
        ) : null}
        {activeTab === 'Assignments' ? (
          <ProjectAssignmentList assigneeId={id} />
        ) : null}
        {activeTab === 'Next of Kin' ? (
          <NextOfKinDetail labourResourceId={id} />
        ) : null}
        {activeTab === 'Skills' ? (
          <SkillDetail labourResourceId={id} labourPath={'employees'} />
        ) : null}
        {activeTab === 'Certifications' ? (
          <CertificationDetail
            labourResourceId={id}
            labourPath={'employees'}
            labour={employee}
          />
        ) : null}
        {activeTab === 'CV' ? (
          <CvExportList labour={employee} labourResourceId={id!} />
        ) : null}
      </div>
    </div>
  );
};

export const Employee = () => <EmployeePage />;
