import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnRuleForm } from '../../../components/Export/ColumnRuleForm';
import FormCard from '../../../components/FormCard/FormCard';
import { GET_EXPORT_CONFIGURATIONS } from '../../../graphql/queries/export-configurations';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { SAVE_EXPORT_CONFIGURATION } from '../../../graphql/mutations/export-configurations';
import { GET_GROUPS } from '../../../graphql/queries/users';
import { GET_TIMESHEET_CONFIGURATIONS } from '../../../graphql/queries/timesheet-configurations';
import { useToast } from '../../../context/ToastContext';

export const ExportConfigurationPage = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const [fetchExportConfigurations, { data }] = useLazyQuery(
    GET_EXPORT_CONFIGURATIONS,
    {
      variables: { input: { id } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (id) {
      fetchExportConfigurations({ variables: { input: { id } } });
    }
  }, [id, isUpdate]);

  const [fetchTimesheetConfigurations, { data: timesheetsData }] = useLazyQuery(
    GET_TIMESHEET_CONFIGURATIONS,
    {
      variables: { input: { limit: 1000, offset: 0 } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchGroups({ variables: { input: { limit: 1000, offset: 0 } } });
    fetchTimesheetConfigurations({
      variables: { input: { limit: 1000, offset: 0 } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [fetchGroups, { data: groupsData }] = useLazyQuery(GET_GROUPS, {
    variables: { input: { limit: 1000, offset: 0 } },
    fetchPolicy: 'network-only',
  });

  const groupResults = groupsData?.getGroups?.results || [];
  const timesheetResults =
    timesheetsData?.getTimesheetConfigurations?.results || [];

  const [saveExportConfiguration] = useMutation(SAVE_EXPORT_CONFIGURATION);

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveExportConfiguration({
        variables: {
          id: values?.id,
          input: {
            ...values,
            columnRules: values.columns,
            type: 'TIME_TRACKING',
            columns: undefined,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (data?.saveExportConfiguration?.success) {
        addToast('Export configuration saved successfully', 'success');
        navigate('/time/timesheets/exports');
      } else {
        addToast('Error saving export configuration', 'error');
      }
    } catch (error) {
      addToast('Error saving export configuration', 'error');
      console.error(error);
    }
  };

  const [ExportConfiguration] = data?.getExportConfigurations?.results || [];

  const initialValues = {
    id: ExportConfiguration?.id,
    name: ExportConfiguration?.name,
    description: ExportConfiguration?.description,
    groups: ExportConfiguration?.groups || [],
    includeIds: ExportConfiguration?.includeIds || [],
    columns: ExportConfiguration?.columnRules || [],
    enabled: ExportConfiguration?.enabled,
  };

  const rateConfig = {
    formSections: [
      {
        title: 'Export Configuration',
        fields: [
          {
            title: 'Export Name',
            id: 'name',
            type: 'input',
          },
          {
            title: 'Description',
            id: 'description',
            type: 'textarea',
          },
          {
            title: 'Enabled',
            id: 'enabled',
            type: 'switch',
          },
          {
            id: 'granularity',
            type: 'dropdown',
            title: 'Granularity',
            options: [
              { value: 'hourly', name: 'Hourly' },
              { value: 'day_percentage', name: 'Daily' },
            ],
            description:
              'Note: If exports have mixed granularity, times will be converted based on the length of the working day.',
          },
          {
            id: 'includeIds',
            type: 'multiselect',
            title: 'Included Timesheets',
            options: timesheetResults.map((group: any) => ({
              name: group.name,
              value: group.id,
            })),
            description: 'Select timesheets to be combined in this export.',
          },
          {
            id: 'groups',
            type: 'multiselect',
            title: 'Allowed Groups',
            options: groupResults.map((group: any) => ({
              name: group.name,
              value: group.id,
            })),
            description: 'Select groups allowed to export this data.',
          },
        ],
      },
      {
        title: 'Column Configuration',
        components: [<ColumnRuleForm />],
      },
    ],
  } as FormConfig;

  return (
    <div className="flex w-full flex-col gap-y-2">
      <FormCard
        config={rateConfig}
        validationSchema={null}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </div>
  );
};
