import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { GET_PROJECT_ACTIVITY_TYPES } from '../../../graphql/queries/projects';
import { SAVE_PROJECT_ACTIVITY_TYPE } from '../../../graphql/mutations/projects';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import {
  GET_PROGRESS_ACTIVITY_CODES,
  SEARCH_PROGRESS_ACTIVITY_CATEGORIES,
} from '../../../graphql/queries/progress-activity-submissions';
import { useToast } from '../../../context/ToastContext';

export const SaveProjectActivityType = () => {
  let { id, projectId } = useParams();
  const navigate = useNavigate();
  const { useQuery, useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const { data } = useQuery(GET_PROJECT_ACTIVITY_TYPES, {
    variables: { projectId, input: { id } },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  const { data: progressCodeData } = useQuery(GET_PROGRESS_ACTIVITY_CODES, {
    variables: { projectId, input: { offset: 0, limit: 100 } },
    fetchPolicy: 'network-only',
  });

  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [searchProjectActivityCategories, { data: pagResults }] = useLazyQuery(
    SEARCH_PROGRESS_ACTIVITY_CATEGORIES,
    { fetchPolicy: 'network-only' }
  );

  const performSearch = async () => {
    try {
      await searchProjectActivityCategories({
        variables: {
          searchTerm: debouncedSearchTerm,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const [ProjectActivityType] = data?.getProjectActivityTypes?.results || [];

  const pacResults =
    pagResults?.searchProgressActivityCategories?.results || [];

  const progressCodes =
    progressCodeData?.getProgressActivityCodes?.results || [];

  const [saveProjectActivityType] = useMutation(SAVE_PROJECT_ACTIVITY_TYPE);

  const initialValues = id
    ? {
        id,
        projectId,
        name: ProjectActivityType?.name,
        progressActivityCategoryId:
          ProjectActivityType?.progressActivityCategoryId,
        defaultProgressCodeId: ProjectActivityType?.defaultProgressCode?.id,
      }
    : { name: undefined, description: undefined };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await saveProjectActivityType({
        variables: {
          id: values?.id,
          input: {
            ...values,
            projectId,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      navigate(`/projects/${projectId}?tab=Activities`);
      addToast('Activity saved successfully', 'success');
    } catch (error) {
      console.error(error);
      addToast('Failed to save Activity', 'error');
    }
  };

  const ProjectActivityTypeConfig = {
    formSections: [
      {
        title: 'Activity Type',
        description: 'Basic details about the activity',
        fields: [
          {
            id: 'name',
            type: 'input',
            title: 'Activity Name',
            placeholder: 'Some activity name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'progressActivityCategoryId',
            type: 'combobox',
            placeholder: 'Select a category',
            title: 'Activity Category',
            valueTransformer: (id: string) =>
              pagResults?.searchProgressActivityCategories?.results?.find(
                (result: any) => result.id === id
              )?.name,
            onChange: (value: string) => {
              setSearchTerm(value);
            },
            options: pacResults,
          },

          {
            id: 'defaultProgressCodeId',
            type: 'dropdown',
            placeholder: 'Select a default progress code',
            title: 'Default Progress Code',
            options: progressCodes.map(
              (code: { id: string; code: string }) => ({
                id: code.id,
                name: code.code,
                value: code.id,
              })
            ),
          },
        ],
      },
    ],
  } as FormConfig;

  const ProjectActivityTypeCreationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  return (
    <FormCard
      key={`${ProjectActivityTypeConfig?.title}`}
      config={ProjectActivityTypeConfig}
      validationSchema={ProjectActivityTypeCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
