import { Formik } from 'formik';
import * as Yup from 'yup';
import FormSection from '../../../../components/FormSection/FormSection';
import { useContext } from 'react';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import { Permission } from '../../../../types/Permissions';
import DocViewer, {
  DocViewerRenderers,
  IDocument,
} from '@cyntler/react-doc-viewer';
import ComboBox from '../../../../components/Inputs/ComboBox';
import DatePicker from '../../../../components/Inputs/DatePicker';
import { FileInput } from '../../../../components/Inputs/FileInput';
import FormSubmit from '../../../../components/Buttons/FormSubmit';
import moment from 'moment';
import WarningMessage from '../../../../components/Validation/WarningMessage';

type CertificationFormProps = {
  certification: any;
  certificationTypes?: any[];
  docs?: IDocument[];
  onDelete?: () => void | Promise<void>;
  onSubmit: (
    values: {},
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (id: any, value: any) => void
  ) => Promise<void>;
  presignedAttachmentUrl?: string;
  setSearchTerm: (searchTerm: string) => void;
};

export function CertificationForm({
  certification,
  certificationTypes,
  docs,
  onDelete,
  onSubmit,
  presignedAttachmentUrl,
  setSearchTerm,
}: CertificationFormProps) {
  const { userProfile } = useContext(UserProfileContext);

  const initialValues = certification?.id
    ? {
        id: certification?.id,
        certificationType: certification?.certificationType?.id,
        issueDate: certification?.issueDate
          ? Number(certification?.issueDate)
          : undefined,
        expiryDate: certification?.expiryDate
          ? Number(certification?.expiryDate)
          : undefined,
      }
    : {};

  const validationSchema = Yup.object().shape({
    expiryDate: Yup.string(),
    certificationType: Yup.string().required('Certification type is required'),
  });

  return (
    <div className="flex flex-col my-4 gap-y-4">
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        // @ts-ignore
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          await onSubmit(values, setSubmitting, setFieldValue);
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          isValid,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <>
              <FormSection
                title={
                  certification?.id
                    ? certification?.certificationType?.name
                    : 'Certification'
                }
              >
                {userProfile?.permissions.includes(
                  Permission.DownloadCertificationAttachments
                ) &&
                presignedAttachmentUrl &&
                docs ? (
                  <>
                    <h3>Latest Certification Preview:</h3>
                    <DocViewer
                      style={{
                        height: '70vh',
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}
                      documents={docs}
                      config={{
                        header: {
                          disableHeader: true,
                          disableFileName: true,
                          retainURLParams: true,
                        },

                        pdfZoom: {
                          defaultZoom: 0.6,
                          zoomJump: 0.2,
                        },
                      }}
                      pluginRenderers={DocViewerRenderers}
                      prefetchMethod="GET"
                    />
                  </>
                ) : null}
                {!certification?.id ? (
                  <ComboBox
                    valueTransformer={(id: string) =>
                      certificationTypes?.find(
                        (result: any) => result.id === id
                      )?.name
                    }
                    question={{
                      id: 'certificationType',
                      title: 'Certification Type',
                      description:
                        'This must match an existing certification name',
                      onChange: (value: string) => {
                        setSearchTerm(value);
                      },
                      options: certificationTypes?.map(
                        (certification: any) => ({
                          id: certification.id,
                          name: certification.name,
                        })
                      ),
                    }}
                    placeholder={'Start typing the certification name'}
                    handleChange={(options) =>
                      setFieldValue('certificationType', options)
                    }
                    value={values['certificationType']}
                    validation={errors['certificationType']}
                  />
                ) : null}
                <DatePicker
                  id={'issueDate'}
                  title={'Issue Date'}
                  value={values['issueDate']}
                  inputTransformer={(value: Date) => new Date(value).getTime()}
                  handleChange={(item: any) => setFieldValue('issueDate', item)}
                  validation={errors['issueDate']}
                />
                <DatePicker
                  id={'expiryDate'}
                  title={'Expiry Date'}
                  value={values['expiryDate']}
                  inputTransformer={(value: Date) => new Date(value).getTime()}
                  handleChange={(item: any) =>
                    setFieldValue('expiryDate', item)
                  }
                  validation={errors['expiryDate']}
                />
                {values['expiryDate'] &&
                moment(values['expiryDate']).isBefore(moment()) ? (
                  <WarningMessage message="This certification has already expired!" />
                ) : null}
                <FileInput
                  id={'file'}
                  label={'Upload Certification'}
                  filetypes={'application/msword, application/pdf'}
                  filetypesDescription={'PDF or DOCX'}
                  setFieldValue={setFieldValue}
                  clearForm={() => setFieldValue('file', undefined)}
                />
              </FormSection>

              <FormSubmit
                disabled={!isValid}
                isLoading={isSubmitting}
                submitText={'Submit'}
                entityName="Certification"
                onDelete={onDelete}
                onSubmit={handleSubmit}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
}
