import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EyeIcon,
  WifiIcon,
} from '@heroicons/react/20/solid';
import {
  ExclamationTriangleIcon,
  LockOpenIcon,
  PencilSquareIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import {
  CheckCircleIcon,
  DocumentChartBarIcon,
  LockClosedIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Buttons/Button';
import { NetworkContext } from '../../../context/NetworkContext';
import { PaginationProvider } from '../../../context/PaginationContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { SAVE_PROGRESS_ACTIVITY_SUBMISSION } from '../../../graphql/mutations/progress-activity-submissions';
import { GET_PROGRESS_ACTIVITY_SUBMISSIONS_SUMMARY } from '../../../graphql/queries/progress-activity-submissions';
import {
  GET_PROJECTS_COUNT,
  SEARCH_PROJECTS,
  SEARCH_PROJECT_OUTCOMES,
} from '../../../graphql/queries/projects';
import { useIndexedDB } from '../../../hooks/useIndexedDB';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Permission } from '../../../types/Permissions';
import { ProjectSelection } from '../../TimeTracking/timesheet/project/search';
import { useProgressDataRetrieval } from './hooks/useProgressDataRetrieval';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import NoProjects from '../../../components/NoData/NoProjects';

const renderDays = (selectedMonth: number, selectedYear: number) => {
  const startOfMonth = moment([selectedYear, selectedMonth - 1]);
  const endOfMonth = moment(startOfMonth).endOf('month');

  const startOfCalendar = startOfMonth.startOf('week');
  const endOfCalendar = endOfMonth.endOf('week');

  const today = moment();

  const days = [];
  let currentDay = moment(startOfCalendar);

  while (currentDay <= endOfCalendar) {
    days.push({
      date: currentDay.format('YYYY-MM-DD'),
      isCurrentMonth: currentDay.month() === selectedMonth - 1,
      isToday: currentDay.isSame(today, 'day'),
    });

    currentDay.add(1, 'days');
  }

  return days;
};

const CalendarDay = ({
  day,
  projectActivity,
  selectedProject,
  selectedOutcome,
  navigate,
  toggleProgressSubmission,
}: any) => {
  const { userProfile } = useContext(UserProfileContext);
  const { isOffline } = useContext(NetworkContext);

  const { db, readItem } = useIndexedDB({
    objectStoreName: 'progress_submissions',
  });

  const dateLabel = day.date.split('-').pop().replace(/^0/, '');
  const isActivityDay = projectActivity?.find((entry: any) =>
    moment(day.date).isSame(entry.date)
  );
  const dayClassName = day.isCurrentMonth
    ? 'bg-white'
    : 'bg-gray-50 text-gray-500';
  const timeClassName = day.isToday ? 'bg-green-600 text-white' : 'text-black';

  const handleToggleLock = async () => {
    if (selectedProject?.id) {
      await toggleProgressSubmission(
        selectedProject.id,
        day.date,
        isActivityDay?.locked,
        selectedOutcome?.id,
        'locked'
      );
    }
  };

  const handleToggleApproved = async () => {
    if (selectedProject?.id) {
      await toggleProgressSubmission(
        selectedProject.id,
        day.date,
        isActivityDay?.approved,
        selectedOutcome?.id,
        'approved'
      );
    }
  };

  const handleNavigate = (path: any) => {
    if (selectedProject?.id && selectedOutcome?.id) {
      navigate(
        `/progress/submissions/${selectedProject.id}/${selectedOutcome.id}/${day.date}${path}`
      );
    }
  };

  const readLocalItems = async () => {
    const submission = await readItem(
      `${selectedProject.id}_${selectedOutcome?.id}_${day.date}`
    );
    setHasLocalProgressSubmission(submission?.progressSubmission);
  };

  useEffect(() => {
    if (db) {
      readLocalItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, isOffline]);

  const [hasLocalProgressSubmission, setHasLocalProgressSubmission] =
    useState();

  return (
    <div
      key={day.date}
      className={`${dayClassName} flex items-center justify-between`}
    >
      <div className="w-full">
        <time
          dateTime={day.date}
          className={`${timeClassName} flex h-8 w-full items-center justify-center rounded-sm font-semibold text-lg`}
        >
          {dateLabel}
        </time>
        <div className="flex items-center py-2 justify-center">
          {isActivityDay ? (
            <div className="px-2 py-2">
              <hr className="mb-2" />
              <div className="gap-y-2 grid lg:grid-cols-2 gap-x-2">
                <div className="col-span-2">
                  <Button
                    style={{
                      width: '100%',
                      background: isActivityDay.locked ? 'black' : '#8a8a8a',
                    }}
                    isDisabled={
                      !userProfile?.permissions.includes(
                        Permission.LockProgressSubmission
                      )
                    }
                    onClick={handleToggleLock}
                    text={
                      <div className="flex items-center gap-x-2">
                        {isActivityDay.locked ? (
                          <LockClosedIcon className="w-5 h-5" />
                        ) : (
                          <LockOpenIcon className="w-5 h-5" />
                        )}
                        <div className="hidden lg:block">
                          {isActivityDay.locked ? 'Locked' : 'Unlocked'}
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="col-span-2">
                  <Button
                    isDisabled={
                      !userProfile?.permissions.includes(
                        Permission.ApproveProgressSubmission
                      ) || isActivityDay.locked
                    }
                    style={{
                      width: '100%',
                      background: isActivityDay.approved
                        ? '#17a34a'
                        : '#f44336',
                    }}
                    onClick={handleToggleApproved}
                    text={
                      <div className="flex items-center gap-x-2">
                        {isActivityDay.approved ? (
                          <CheckCircleIcon className="w-5 h-5" />
                        ) : (
                          <XCircleIcon className="w-5 h-5" />
                        )}
                        <div className="hidden lg:block">
                          {isActivityDay.approved ? 'Approved' : 'Unapproved'}
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="col-span-2">
                  <Button
                    style={{
                      width: '100%',
                      background: isActivityDay.locked
                        ? 'cornflowerblue'
                        : 'orange',
                    }}
                    onClick={() => handleNavigate('')}
                    text={
                      <div className="flex items-center gap-x-2">
                        {isActivityDay.locked ? (
                          <EyeIcon className="w-5 h-5" />
                        ) : (
                          <PencilSquareIcon className="w-5 h-5" />
                        )}
                        <div className="hidden lg:block">
                          {isActivityDay.locked ? 'View' : 'Edit'}
                        </div>
                      </div>
                    }
                  />
                </div>
                {!userProfile?.permissions.includes(
                  Permission.GenerateProgressReports
                ) ? null : (
                  <div className="col-span-2">
                    <Button
                      style={{ width: '100%', background: 'purple' }}
                      onClick={() => handleNavigate('/exports')}
                      text={
                        <div className="flex items-center gap-x-2">
                          <DocumentChartBarIcon className="w-5 h-5" />
                          <div className="hidden lg:block">Reports</div>
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          ) : day.isCurrentMonth ? (
            <NoProgressReport
              date={day.date}
              selectedProject={selectedProject}
              selectedOutcome={selectedOutcome}
              isOffline={isOffline}
              hasLocalProgressSubmission={hasLocalProgressSubmission}
              navigate={handleNavigate}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const NoProgressReport = ({
  date,
  navigate,
  isOffline,
  hasLocalProgressSubmission,
}: any) => (
  <div className="flex gap-y-2 w-full flex-col mb-2 items-center justify-center">
    {isOffline || hasLocalProgressSubmission ? (
      <WifiIcon
        className={`w-10 h-10 text-gray-200 ${
          hasLocalProgressSubmission ? 'text-orange-200' : ''
        }`}
      />
    ) : (
      <ExclamationTriangleIcon className="w-10 h-10 text-orange-300" />
    )}
    {hasLocalProgressSubmission ? (
      <p className="text-xs px-2 text-center">
        Offline Submission <br />
        (may require sync)
      </p>
    ) : null}
    {isOffline || hasLocalProgressSubmission ? null : (
      <p className="text-xs px-2 text-center">No Progress Report</p>
    )}
    <div className="w-full flex items-center justify-center">
      <Button
        onClick={() => navigate('')}
        aria-label={`Add Progress Submission for ${date}`}
      >
        <PlusCircleIcon className="w-5 h-5" />
      </Button>
    </div>
  </div>
);

export const SubmissionsHome = () => {
  const { id, outcomeId } = useParams();
  const navigate = useNavigate();

  const thisMonth = [moment().month() + 1, moment().year()];

  const { isOffline } = useContext(NetworkContext);

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOutcome, setSelectedOutcome] = useState<any>({});
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [selectedYear, setSelectedYear] = useState(moment().year());

  const startOfMonth = moment([selectedYear, selectedMonth - 1]);
  const endOfMonth = moment(startOfMonth).endOf('month');

  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [fetchProjects, { data: projectsData }] = useLazyQuery(
    SEARCH_PROJECTS,
    {
      variables: { input: { id }, searchTerm: debouncedSearchTerm },
      fetchPolicy: 'network-only',
    }
  );

  const [
    fetchProjectsCount,
    { data: projectsCountData, loading: loadingProjectsCount },
  ] = useLazyQuery(GET_PROJECTS_COUNT, {
    variables: {},
    fetchPolicy: 'network-only',
  });

  const [fetchOutcomes, { data: outcomesData }] = useLazyQuery(
    SEARCH_PROJECT_OUTCOMES,
    {
      variables: {
        input: { id: outcomeId },
        searchTerm: '',
        projectId: selectedProject?.id,
      },
      fetchPolicy: 'network-only',
    }
  );

  const { retrieveAndCacheDataFromServer, isSyncing } =
    useProgressDataRetrieval(
      { id: selectedProject?.id },
      { id: selectedOutcome?.id }
    );

  useEffect(() => {
    setSelectedProject({ name: '' });
    setSelectedOutcome({ name: '' });
  }, [activeOrganisation]);

  useEffect(() => {
    if (selectedProject?.id && outcomeId) {
      fetchOutcomes();
    }
  }, [id, outcomeId, activeOrganisation, selectedProject?.id, fetchOutcomes]);

  useEffect(() => {
    fetchProjects();
  }, [id, outcomeId, activeOrganisation, fetchProjects]);

  useEffect(() => {
    fetchProjectsCount();
  }, [fetchProjectsCount]);

  useEffect(() => {
    if (selectedProject?.id) {
      setSelectedOutcome({ name: '' });
      fetchOutcomes();
    }
  }, [fetchOutcomes, selectedProject?.id]);

  const days = renderDays(selectedMonth, selectedYear);

  const [saveProgressSubmission] = useMutation(
    SAVE_PROGRESS_ACTIVITY_SUBMISSION
  );
  const [fetchProgressSubmissions, { data: projectActivitySubmissions }] =
    useLazyQuery(GET_PROGRESS_ACTIVITY_SUBMISSIONS_SUMMARY, {
      fetchPolicy: 'network-only',
    });

  const toggleProgressSubmission = async (
    id: string,
    date: string,
    isTrue: boolean,
    outcomeId: string,
    key?: string
  ) => {
    await saveProgressSubmission({
      variables: {
        input: {
          projectId: id,
          date,
          outcomeId,
          [`${key}`]: !isTrue,
        },
      },
    });
    await fetchProgressSubmissions({
      variables: {
        startDate: startOfMonth,
        endDate: endOfMonth,
        projectId: selectedProject?.id,
        outcomeId,
      },
      fetchPolicy: 'network-only',
    });
  };

  const goToNextMonth = () => {
    if (selectedMonth === 12) {
      setSelectedMonth(1);
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const goToPreviousMonth = () => {
    if (selectedMonth === 1) {
      setSelectedMonth(12);
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const projectSearchResults = projectsData?.searchProjects?.results || [];
  const projectCount = projectsCountData?.getProjects?.count || 0;

  const outcomeSearchResults =
    outcomesData?.searchProjectOutcomes?.results || [];

  const projectActivity =
    projectActivitySubmissions?.getProgressSubmissions?.results || [];

  const performSearch = async () => {
    try {
      await fetchProjects({ variables: { searchTerm: debouncedSearchTerm } });
    } catch (err) {
      // do nothing
    }
  };

  useEffect(() => {
    if (id && !searchTerm && projectSearchResults?.length > 0) {
      setSelectedProject(projectSearchResults[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, projectSearchResults]);

  useEffect(() => {
    if (outcomeId && !searchTerm && outcomeSearchResults?.length > 0) {
      setSelectedOutcome(outcomeSearchResults[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomeId, outcomeSearchResults]);

  useEffect(() => {
    if (searchTerm) {
      navigate('/progress/submissions');
    }
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [navigate, searchTerm]);

  useEffect(() => {
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const { db } = useIndexedDB({ objectStoreName: `cached_project_data` });

  useEffect(() => {
    if (
      selectedProject?.id &&
      selectedMonth &&
      selectedYear &&
      !isOffline &&
      selectedOutcome?.id
    ) {
      fetchProgressSubmissions({
        variables: {
          startDate: startOfMonth,
          endDate: endOfMonth,
          projectId: selectedProject?.id,
          outcomeId: selectedOutcome?.id,
        },
        fetchPolicy: 'network-only',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject?.id, selectedMonth, selectedYear, selectedOutcome?.id]);

  useEffect(() => {
    if (!isOffline && db && selectedProject?.id && selectedOutcome?.id) {
      retrieveAndCacheDataFromServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOffline, db, selectedProject?.id, selectedOutcome?.id]);

  if (projectCount === 0) {
    return <NoProjects loading={loadingProjectsCount} />;
  }

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <header className="flex items-center flex-col gap-y-2 md:flex-row justify-between border-b border-gray-200  py-4 lg:flex-none">
        <div className="w-full">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {selectedProject?.name
              ? `${selectedProject?.name} (${selectedProject?.internalId})`
              : ''}
            {selectedOutcome?.name ? ` ${selectedOutcome?.name}` : ''}
          </h1>
        </div>
      </header>
      {!isOffline ? (
        <div className="w-full flex justify-between py-2">
          <ProjectSelection
            projectSearchResults={projectSearchResults}
            setSearchTerm={setSearchTerm}
            setSelectedProject={setSelectedProject}
            selectedProject={selectedProject}
          />
          <ProjectSelection
            placeholder="Search for project scope"
            projectSearchResults={outcomeSearchResults}
            setSearchTerm={setSearchTerm}
            setSelectedProject={setSelectedOutcome}
            selectedProject={selectedOutcome}
            description={
              outcomeSearchResults.length === 0 && selectedProject?.id ? (
                <p className="text-sm p-2 align-middle">
                  It looks like there are no scopes for this project.{' '}
                  <Link
                    to={`/projects/${selectedProject?.id}/outcomes/new`}
                    target="_blank"
                    className="font-extrabold underline"
                  >
                    Click here
                  </Link>{' '}
                  to create one.
                </p>
              ) : null
            }
          />
        </div>
      ) : null}
      <div className="flex items-center justify-evenly  py-2">
        <time
          className="text-lg"
          dateTime={`${selectedYear}-${selectedMonth
            .toString()
            .padStart(2, '0')}`}
        >
          {moment(`${selectedYear}-${selectedMonth}`, 'YYYY-M').format(
            'MMM YYYY'
          )}
        </time>
        <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
          <button
            type="button"
            onClick={goToPreviousMonth}
            className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={() => {
              setSelectedMonth(thisMonth[0]);
              setSelectedYear(thisMonth[1]);
            }}
            className="hidden border-y text-sm border-gray-300 px-2 font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
          >
            This Month
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            type="button"
            onClick={goToNextMonth}
            className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        {!isOffline && db && selectedProject?.id && selectedOutcome?.id ? (
          <Button
            text={'Sync Project'}
            isLoading={isSyncing}
            isDisabled={isSyncing}
            onClick={retrieveAndCacheDataFromServer}
          />
        ) : null}
      </div>
      {selectedProject?.id && selectedOutcome?.id ? (
        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
            <div className="bg-white py-2">
              M<span className="sr-only sm:not-sr-only">on</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">ue</span>
            </div>
            <div className="bg-white py-2">
              W<span className="sr-only sm:not-sr-only">ed</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">hu</span>
            </div>
            <div className="bg-white py-2">
              F<span className="sr-only sm:not-sr-only">ri</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">at</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">un</span>
            </div>
          </div>
          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
              {days.map((day) => (
                <CalendarDay
                  key={day.date}
                  day={day}
                  projectActivity={projectActivity}
                  selectedProject={selectedProject}
                  selectedOutcome={selectedOutcome}
                  navigate={navigate}
                  toggleProgressSubmission={toggleProgressSubmission}
                />
              ))}
            </div>
            <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
              {days.map((day) => (
                <CalendarDay
                  key={day.date}
                  day={day}
                  projectActivity={projectActivity}
                  selectedProject={selectedProject}
                  selectedOutcome={selectedOutcome}
                  navigate={navigate}
                  toggleProgressSubmission={toggleProgressSubmission}
                />
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

interface ProgressSubmissions {}

export const ProgressSubmissionsList = (props: ProgressSubmissions) => (
  <PaginationProvider>
    <SubmissionsHome />
  </PaginationProvider>
);
