import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import awsConfig from './aws-exports';
import { SideBar } from './components/Navigation/Navigation';
import { AuthenticatedUserContext } from './context/AuthenticatedUserContext';
import { ContextWrapper } from './context/ContextWrapper';
import { isLocalhost } from './utils/utils';
import Login from './components/Login/Login';
import { Amplify } from 'aws-amplify';
import { useApolloClient } from './hooks/useApolloClient';
import { ApolloProvider } from '@apollo/client';
import moment from 'moment';
import {
  UserProfileContext,
  UserProfileProvider,
} from './context/UserProfileContext';
import { ActiveOrganisationProvider } from './context/ActiveOrganisationContext';
import EULAModal from './components/EULA/EULAModal';
import { NetworkContext } from './context/NetworkContext';
import 'leaflet/dist/leaflet.css';
import { ToastContainer } from './components/ToastContainer/ToastContainer';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

const EULAModalContainer = () => {
  const [isEULAModalOpen, setIsEULAModalOpen] = useState(false);
  const { userProfile, isLoading: isLoadingProfile } =
    useContext(UserProfileContext);
  const { user } = useContext(AuthenticatedUserContext);

  const { isOffline } = useContext(NetworkContext);

  useEffect(() => {
    if (
      !isLoadingProfile &&
      userProfile?.personalDetails?.firstName &&
      !isOffline
    ) {
      setIsEULAModalOpen(!userProfile?.signedEULAVersion);
    }

    if (isOffline) {
      setIsEULAModalOpen(false);
    }
  }, [
    JSON.stringify(userProfile?.personalDetails),
    isOffline,
    userProfile?.signedEULAVersion,
  ]);

  return user.isAuthenticated && !isLoadingProfile ? (
    <EULAModal open={isEULAModalOpen} setOpen={setIsEULAModalOpen} />
  ) : null;
};

function App() {
  const { user } = useContext(AuthenticatedUserContext);
  const apolloClient = useApolloClient();
  if (!user.isAuthenticated) {
    return <Login />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <ActiveOrganisationProvider>
        <UserProfileProvider>
          <EULAModalContainer />
          <div className="bg-gray-100 w-full">
            <SideBar>
              <>
                <Outlet />
                <hr className="my-2" />
                <a
                  target="_blank"
                  aria-description="Link to EULA"
                  className="text-xs text-center w-full md:text-left"
                  href="/eula.html"
                >
                  engOS Privacy, GDPR and End User License Agreement.
                </a>
              </>
            </SideBar>
          </div>
        </UserProfileProvider>
      </ActiveOrganisationProvider>
    </ApolloProvider>
  );
}

const AppWrapper = () => {
  return (
    <ContextWrapper>
      <>
        <ToastContainer />
        <App />
      </>
    </ContextWrapper>
  );
};
export default AppWrapper;
