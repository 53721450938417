import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect, useState } from 'react';

import { BlueBadge } from '../../../components/Badges/Badges';
import FormCard from '../../../components/FormCard/FormCard';
import RateConfigurationByKey, {
  TableValue,
} from '../../../components/RateConfiguration/RateConfigurationByKey';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import { useToast } from '../../../context/ToastContext';
import { SAVE_SUBCONTRACTOR } from '../../../graphql/mutations/subcontractors';
import { GET_ACTIVITY_CODES } from '../../../graphql/queries/activity-codes';
import { GET_CUSTOM_FIELDS } from '../../../graphql/queries/custom-data';
import { GET_SUBCONTRACTORS } from '../../../graphql/queries/subcontractors';
import { GET_GROUPS, SEARCH_USERS } from '../../../graphql/queries/users';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Currency } from '../../../types/Currency';
import { FormConfig, FormSection } from '../../../types/Form';
import { GET_ROLES_SUMMARY } from '../../../graphql/queries/roles';
import { LoadingSpinner } from '../../../components/Loading/LoadingSpinner';
import useDebounce from '../../../hooks/useDebounce';

export const SaveSubcontractor = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation, useLazyQuery } = useOrganisationAwareApollo();

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const financeModuleEnabled = activeOrganisation?.financeModuleEnabled;

  const [searchUsers, { data: usersData }] = useLazyQuery(SEARCH_USERS);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const { addToast } = useToast();

  const [defaultRate, setDefaultRate] = useState<TableValue[]>([]);

  const isUpdate = !!id;

  const { data, refetch, loading } = useQuery(GET_SUBCONTRACTORS, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  const { data: jobRolesData } = useQuery(GET_ROLES_SUMMARY, {});

  const { data: activityCodes, refetch: fetchActivityCodes } = useQuery(
    GET_ACTIVITY_CODES,
    {
      variables: {},
      fetchPolicy: 'network-only',
    }
  );

  const [fetchCustomFields, { data: customFieldsData }] = useLazyQuery(
    GET_CUSTOM_FIELDS,
    {
      variables: {
        labourResourceId: id,
        isFetchingForCreatingUser: !id,
        onlyEnabled: true,
      },
      fetchPolicy: 'network-only',
    }
  );

  const performUserSearch = async () => {
    try {
      await searchUsers({
        variables: {
          searchTerm: debouncedSearchTerm,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 1) {
      performUserSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const activityCodesResults = activityCodes?.getActivityCodes?.results || [];
  const filteredAndMappedActivityCodes =
    activityCodesResults.filter(
      (code: any) => code.enabled && code.useResourceDefaultRate
    ) || [];
  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
      fetchActivityCodes();
    }
  }, [id, refetch, isUpdate, fetchActivityCodes]);

  useEffect(() => {
    fetchCustomFields();
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate, fetchCustomFields]);

  const jobRoles = jobRolesData?.getRoles?.results || [];

  const customFields = customFieldsData?.getCustomFieldGroups?.results || [];

  const [subcontractor] = data?.getSubcontractors?.results || [];

  const [selectedCompany, setSelectedCompany] = useState<any>(
    subcontractor?.company
  );

  const [saveSubcontractor] = useMutation(SAVE_SUBCONTRACTOR);

  useEffect(() => {
    if (subcontractor?.company) {
      setSelectedCompany(subcontractor.company);
    }
  }, [subcontractor?.company]);

  const currencies = Object.values(Currency).filter((e: any) => isNaN(e));

  const initialDefaultRates = currencies.reduce((acc: any, currency: any) => {
    const currencyActivities = filteredAndMappedActivityCodes.map(
      (activityCode: any) => ({
        columnId: currency,
        name: activityCode?.name,
        rowId: activityCode.key,
        value: 0,
      })
    );
    return [...acc, ...currencyActivities];
  }, []);

  const projectCustomData = subcontractor?.customData || [];

  const initialValues = id
    ? {
        ...subcontractor,
        defaultRoleId: subcontractor?.defaultRole.id || '',
        ...projectCustomData.reduce(
          (acc: Record<string, any>, customItem: any) => {
            acc[`custom:${customItem.key}`] = customItem.value;
            return acc;
          },
          {}
        ),
      }
    : { type: 'SUBCONTRACTOR', hasUserLogin: true };

  useEffect(() => {
    if (subcontractor?.defaultRate && subcontractor?.defaultRate.length > 0) {
      setDefaultRate(
        subcontractor?.defaultRate?.map((itemRate: any) => ({
          columnId: itemRate.currency,
          name:
            itemRate.name ??
            filteredAndMappedActivityCodes.find(
              (code: any) => code.id === itemRate.key
            )?.name,
          rowId: itemRate.key,
          value: parseFloat(itemRate.rate),
        }))
      );
    } else {
      setDefaultRate(initialDefaultRates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcontractor]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .nullable()
      .when('hasUserLogin', {
        is: (hasUserLogin: boolean) => hasUserLogin,
        then: (emailValidation) =>
          emailValidation
            .email('Not a valid email address')
            .required('Email is required'),
        otherwise: (emailValidation) =>
          emailValidation.email('Not a valid email address'),
      }),
    hasUserLogin: Yup.boolean(),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const customData: { key: string; value: any }[] = [];
    const filteredValues = Object.keys(values).reduce(
      (acc: Record<string, string>, key) => {
        if (key.startsWith('custom:')) {
          customData.push({
            key: key?.replace('custom:', ''),
            value: values[key],
          });
        } else if (key === 'defaultRole') {
          acc['defaultRoleId'] = values[key];
        } else {
          acc[key] = values[key];
        }
        return acc;
      },
      {}
    );

    try {
      const { data } = await saveSubcontractor({
        variables: {
          id: values?.id,
          input: {
            ...filteredValues,
            companyId: selectedCompany?.id,
            isOwnerOfCompany: undefined,
            company: undefined,
            defaultRate: defaultRate
              ?.map((rateOption: any) => ({
                id: `${rateOption?.columnId}_${rateOption?.rowId}`,
                name: rateOption?.name,
                currency: rateOption?.columnId,
                key: rateOption?.rowId,
                rate: parseFloat(rateOption?.value),
              }))
              .filter((rate: any) =>
                filteredAndMappedActivityCodes
                  .map((code: any) => code.id)
                  .includes(rate.key)
              ),
            customData,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,

            missingOrInvalidCertifications: undefined,
          },
        },
      });
      addToast('Subcontractor saved', 'success');
      setSubmitting(false);
      if (data.saveSubcontractor.success) {
        if (id) {
          navigate(
            `/labour/${
              values?.type === 'EMPLOYEE' ? 'employees' : 'subcontractors'
            }/${id}`
          );
        } else {
          navigate(`/labour/subcontractors/list`);
        }
      }
    } catch (error) {
      console.error(error);
      addToast('Error saving subcontractor', 'error');
    }
  };

  const [fetch, { data: groupsData }] = useLazyQuery(GET_GROUPS, {
    variables: { input: { limit: 1000, offset: 0 } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetch({ variables: { input: { limit: 1000, offset: 0 } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const groupResults = groupsData?.getGroups?.results || [];

  const subcontractorConfig = {
    title: '',
    description: '',
    formSections: [
      {
        title: 'Subcontractor',
        description: 'Basic details about the Subcontractor',
        fields: [
          {
            title: 'First Name',
            id: 'firstName',
            type: 'input',
            required: true,
          },
          {
            title: 'Last Name',
            id: 'lastName',
            type: 'input',
            required: true,
          },
          {
            title: 'Nationality',
            id: 'nationality',
            type: 'input',
          },
          {
            title: 'Country of Residence',
            id: 'countryOfResidence',
            type: 'input',
          },
          {
            title: 'Email',
            id: 'email',
            description:
              usersData?.searchUsers?.count > 0 ? 'Email in use' : '',
            type: id ? 'input' : 'combobox',
            disabled: !!id || usersData?.searchUsers?.count,
            placeholder: !id
              ? 'Start typing the new users email address'
              : 'No email configured for this user',
            onChange: (value: string) => {
              setSearchTerm(value);
            },
            options:
              searchTerm.length > 5 &&
              (!usersData?.searchUsers?.count ||
                usersData?.searchUsers?.count === 0)
                ? [
                    {
                      id: searchTerm.trim(),
                      name: searchTerm.trim(),
                      detail: !Yup.string().email().isValidSync(searchTerm)
                        ? 'Not valid email address'
                        : 'Available',
                    },
                  ]
                : [],
            required: true,
          },
          {
            title: 'Job Title',
            id: 'jobTitle',
            type: 'input',
            required: false,
          },
          {
            title: 'Default Role',
            id: 'defaultRoleId',
            type: 'dropdown',
            options: jobRoles.map((role: { id: string; name: string }) => ({
              name: role.name,
              value: role.id,
            })),
            required: false,
          },
          {
            title: 'Personal Profile',
            id: 'personalProfile',
            type: 'textarea',
            required: false,
          },
          {
            title: 'Project Experience',
            id: 'projectExperience',
            type: 'richtextinput',
            required: false,
          },
          ...(!id
            ? [
                {
                  id: 'groupMemberships',
                  type: 'multiselect',
                  title: 'Groups',
                  options: groupResults.map((group: any) => ({
                    name: group.name,
                    value: group.id,
                  })),
                },
              ]
            : []),
          {
            title: 'Company',
            id: 'companyId',
            type: 'COMPANY_SELECTION',
            handleCreateNew: () => {
              window.open('/companies/new', '_blank');
            },
            setSelectedCompany: (company: any) => {
              setSelectedCompany(company);
            },
            selectedCompany: selectedCompany,
          },
          {
            title: 'Internal Id',
            id: 'internalId',
            type: 'input',
          },
          ...(id
            ? [
                {
                  title: 'Type',
                  id: 'type',
                  type: 'dropdown',
                  description:
                    'Note: Changing this will effect where this user is listed and may affect other parts of the system. Be sure you know what you are doing!',
                  options: [
                    { name: 'Subcontractor', value: 'SUBCONTRACTOR' },
                    { name: 'Employee', value: 'EMPLOYEE' },
                  ],
                },
              ]
            : []),
          ...(!id
            ? [
                {
                  title: 'Auto Create Internal Id',
                  id: 'autoCreateId',
                  type: 'switch',
                  description:
                    'Auto create the internal ID (note: will only succeed if IDs are numeric)',
                },
                {
                  title: 'Create User Login',
                  id: 'hasUserLogin',
                  type: 'switch',
                  description:
                    'Should this subcontractor be able to log into the application?',
                },
              ]
            : []),
        ],
      },
      ...(customFields?.length > 0
        ? customFields.map((field: any) => ({
            title: field.name,
            fields: field.custom_fields.map((customField: any) => ({
              title: customField.name,
              id: `custom:${customField.id}`,
              type: customField.type === 'longText' ? 'textarea' : 'input',
              description: customField.description,
            })),
          }))
        : []),
    ],
  } as FormConfig;

  if (financeModuleEnabled) {
    subcontractorConfig?.formSections?.push({
      title: 'Rates',
      description: 'Default Rates',
      components: [
        <RateConfigurationByKey
          value={defaultRate}
          handleChange={(item) => setDefaultRate(item)}
          question={{
            tableOptions: filteredAndMappedActivityCodes?.map((key: any) => ({
              heading: {
                id: key.id,
                name: key.name,
                colour: key?.colour,
              },
              options: currencies.map((entry) => ({
                heading: {
                  id: Object.entries(Currency).find(
                    (item: any) => item[1] === entry
                  )?.[0],
                  name: Object.values(Currency).find(
                    (currency) => currency === entry
                  ),
                },
              })) as {
                heading?: {
                  id: string;
                  name: string;
                };
              }[],
            })),
          }}
        />,
      ],
    } as FormSection);
  }

  const renderConfirmationModal = ({ values }: { values: any }) => {
    return (
      <div className="flex flex-col gap-y-4">
        <div className="text-gray-500">
          You are about to save a subcontractor with the following details,
          please ensure they are correct:
        </div>
        {subcontractorConfig?.formSections?.[0].fields.map((field) => {
          return (
            <div>
              <div className="text-lg font-medium text-gray-900">
                {field.title}
              </div>
              {field.id === 'groupMemberships' ? (
                <div className="flex gap-x-2">
                  {values[field.id]?.map((groupId: string) => (
                    <BlueBadge
                      text={
                        groupResults.find((group: any) => group.id === groupId)
                          ?.name
                      }
                    />
                  ))}
                </div>
              ) : field.id === 'defaultRoleId' ? (
                <p className="text-gray-500">
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: jobRoles.find(
                          (role: any) => role.id === values[field.id]
                        )?.name,
                      }}
                    />
                  }
                </p>
              ) : field.id === 'companyId' ? (
                <p className="text-gray-500">
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedCompany?.companyName,
                      }}
                    />
                  }
                </p>
              ) : (
                <p className="text-gray-500">
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: values[field.id],
                      }}
                    />
                  }
                </p>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <FormCard
      key={`${subcontractorConfig?.title}`}
      config={subcontractorConfig}
      renderConfirmationModal={renderConfirmationModal}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
