import { useState } from 'react';
import { DeletionDialogue } from './DeletionDialogue';
import { Button } from '../Buttons/Button';

type DeleteButtonProps = {
  children: React.ReactNode;
  isDeleting?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  modalText: string;
  modalTitle: string;
  onClick?: () => void;
  onDelete: () => void;
};

export function DeleteButton({
  children,
  isDeleting = false,
  isDisabled = false,
  isLoading = false,
  modalText,
  modalTitle,
  onClick = () => {},
  onDelete,
}: DeleteButtonProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <DeletionDialogue
        showDeleteModal={showModal}
        setShowDeleteModal={setShowModal}
        onDelete={() => {
          onDelete();
          setShowModal(false);
        }}
        deletionConfirmationText={modalText}
        deleteTitle={modalTitle}
        isDeleting={isDeleting}
      />
      <Button
        isLoading={isLoading}
        isDisabled={isDisabled || isLoading}
        className="bg-red-500 hover:bg-red-600"
        onClick={() => {
          onClick();
          setShowModal(true);
        }}
        text={'Delete'}
      >
        {children}
      </Button>
    </>
  );
}
