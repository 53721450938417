// A direct copy of this should exist in the API, but it may be helpful for them not to be in sync - leaving as a copy for now
export enum Permission {
  UpdateActivityCodes = 'update_activity_codes',
  UpdateGroups = 'update_groups',
  UpdateProject = 'update_project',
  UpdateTeams = 'update_teams',
  UpdateRoles = 'update_roles',
  UpdateTimesheetConfiguration = 'update_timesheet_configuration',
  UpdateExportConfiguration = 'update_export_configuration',
  UpdateLabourProfiles = 'update_labour_profiles',
  UpdateCertificationTypes = 'update_certification_types',
  DownloadCertificationAttachments = 'download_certification_attachments',
  UpdateSkillTypes = 'update_skill_types',
  UpdateAssessmentTypes = 'update_assessment_types',

  UpdateCustomData = 'update_custom_data',

  AssignLabourToProject = 'assign_labour_to_project',
  AssignUserToGroup = 'assign_user_to_group',
  AssignProjectFilterToUser = 'assign_project_filter_to_user',
  ApproveTimesheets = 'approve_timesheets',
  UnapproveTimesheets = 'unapprove_timesheets',

  SendAssessments = 'send_assessments',
  ViewAssessments = 'view_assessments',

  ViewSkills = 'view_skills',
  UpdateSkills = 'update_skills',

  ViewProjectAssignmentDetail = 'view_project_assignment_detail',
  ViewProjectAssignmentRates = 'view_project_assignment_rates',
  DeleteAssignmentRole = 'delete_assignment_role',
  DeleteAssessmentAttachments = 'delete_assessment_attachments',
  DeleteAssessmentSubmissions = 'delete_assessment_submissions',
  DeleteCertificationAttachments = 'delete_certification_attachments',
  DeleteCertificationTypes = 'delete_certification_types',
  DownloadAttachments = 'download_attachments',

  ViewContractTemplates = 'view_contract_templates',
  CreateContractTemplates = 'create_contract_templates',
  EditContractTemplates = 'edit_contract_templates',
  SendContracts = 'send_contracts',

  ViewContractSubmissions = 'view_contract_submissions',

  ViewTimesheetSubmissions = 'view_timesheet_submissions',
  ViewProjectDetails = 'view_project_details',
  ViewTimesheets = 'view_timesheets',
  ViewProgressModule = 'view_progress_module',
  SaveProgressSubmission = 'save_progress_submission',
  ViewProgressDashboard = 'view_progress_dashboard',
  ConfigureProgressModule = 'configure_progress_module',
  GenerateProgressReports = 'generate_progress_reports',
  GenerateProjectExports = 'generate_project_exports',
  LockProgressSubmission = 'lock_progress_submission',
  ApproveProgressSubmission = 'approve_progress_submission',
  ViewLabourProfiles = 'view_labour_profiles',
  ViewRoles = 'view_roles',
  ViewRoleRates = 'view_role_rates',
  ViewUsers = 'view_users',
  ViewActivityCodes = 'view_activity_codes',
  ViewCustomData = 'view_custom_data',
  SaveCustomData = 'save_custom_data',
  DeleteCustomData = 'delete_custom_data',

  DeleteCertifications = 'delete_certifications',
  DeleteActivityCodes = 'delete_activity_codes',
  DeleteProgressActivityCodes = 'delete_progress_activity_codes',
  DeleteCompanyProfile = 'delete_company_profile',

  ViewEventTypes = 'view_event_types',
  UpdateEventTypes = 'update_event_types',
}
