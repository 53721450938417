import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useEffect } from 'react';

import FormCard from '../../../../components/FormCard/FormCard';

import { SAVE_BANK_ACCOUNT } from '../../../../graphql/mutations/bank-accounts';
import { GET_BANK_ACCOUNTS } from '../../../../graphql/queries/profiles';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../../types/Form';
import { Currency } from '../../../../types/Currency';
import { LabourHeading } from '../../heading/labour';
import { useToast } from '../../../../context/ToastContext';

export const SaveBankAccount = () => {
  let { labourResourceId, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const isUpdate = !!id;

  const [fetchBankAccounts, { data }] = useLazyQuery(GET_BANK_ACCOUNTS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchBankAccounts({ variables: { input: { id } } });
  }, [id, isUpdate]);

  const [bankaccount] = data?.getBankAccounts?.results || [];

  const [saveBankAccount] = useMutation(SAVE_BANK_ACCOUNT);

  const initialValues = id
    ? {
        ...bankaccount,
      }
    : {};

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveBankAccount({
        variables: {
          id: values?.id,
          labourResourceId,
          input: {
            ...values,
            id: undefined,
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (data?.saveBankAccount?.success) {
        addToast('Bank account saved successfully', 'success');
        navigate(-1);
      } else {
        addToast('Error saving bank account', 'error');
      }
    } catch (error) {
      addToast('Error saving bank account', 'error');
      console.error(error);
    }
  };
  const bankaccountConfig = {
    formSections: [
      {
        title: 'Bank Information',
        description: 'Details about the bank account',
        fields: [
          {
            id: 'bankName',
            type: 'input',
            description: 'Name of the banking institution',
            title: 'Bank Name',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'nameOnAccount',
            type: 'input',
            description: 'This is the name of the account holder',
            title: 'Name on account',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'accountNumber',
            type: 'input',
            title: 'Account Number',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'sortCode',
            type: 'input',
            title: 'Sort Code',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'iban',
            type: 'input',
            title: 'IBAN',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'bic',
            type: 'input',
            title: 'BIC',
            inputTransformer: (text: string) => text,
          },
          {
            id: 'currency',
            type: 'dropdown',
            title: 'Currency',
            options: Object.keys(Currency)
              .filter((e: any) => isNaN(e))
              .map((entry) => ({ name: entry })) as {
              name?: string;
            }[],
          },
          {
            id: 'enabled',
            type: 'switch',
            title: 'Enabled',
          },
          {
            id: 'isBusinessAccount',
            type: 'switch',
            title: 'Is Business Account',
          },
        ],
      },
    ],
  } as FormConfig;

  const BankAccountCreationSchema = Yup.object().shape({
    currency: Yup.mixed<Currency>()
      // @ts-ignore
      .oneOf(Object.keys(Currency))
      .required('Currency is required'),
    bankName: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .typeError('Required field')
      .required(),
    accountNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .nullable(),
    sortCode: Yup.string()
      .min(2, 'Too Short!')
      .max(400, 'Too Long!')
      .nullable(),
    iban: Yup.string().min(2, 'Too Short!').max(400, 'Too Long!').nullable(),
    bic: Yup.string().min(2, 'Too Short!').max(400, 'Too Long!').nullable(),
    enabled: Yup.boolean().nullable(),
  });

  return (
    <>
      <LabourHeading
        id={labourResourceId}
        labourType={
          location.pathname.includes('subcontractors')
            ? 'subcontractors'
            : 'employees'
        }
      />
      <FormCard
        key={`${bankaccountConfig?.title}`}
        config={bankaccountConfig}
        validationSchema={BankAccountCreationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
