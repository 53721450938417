import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ROLES } from '../../../graphql/queries/roles';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { useContext, useEffect, useState } from 'react';
import { Button } from '../../../components/Buttons/Button';
import { Table } from '../../../components/Tables/tables/Table';
import { Currency } from '../../../types/Currency';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import { Modal } from '../../../components/Dialogue/ModalDialogue';
import { SAVE_ROLE } from '../../../graphql/mutations/roles';
import { OrangeBadge } from '../../../components/Badges/Badges';

export const RolePage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const financeModuleEnabled = activeOrganisation?.financeModuleEnabled;

  const { userProfile } = useContext(UserProfileContext);

  const [fetch, { data, loading, error }] = useLazyQuery(GET_ROLES, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  const [saveRole] = useMutation(SAVE_ROLE);

  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] =
    useState(false);

  const [role] = data?.getRoles?.results || [];

  const archived = role?.archivedAt;

  const headerMapping = {
    currency: 'Currency',
    key: 'Key',
    rate: 'Rate',
    updatedAt: 'Updated At',
  } as Record<string, any>;

  const currencies = Object.entries(Currency).filter((e: any) => isNaN(e));

  const sections =
    role?.defaultRates?.length > 0
      ? currencies.map(([key, currencySymbol]) => {
          const ratesForThisCurrency = role?.defaultRates?.filter(
            (rate: any) => rate.currency === key
          );
          return {
            title: `Standard ${key} Rates`,
            content:
              ratesForThisCurrency?.length > 0 ? (
                <Table
                  data={ratesForThisCurrency.map((rate: any) => ({
                    key: rate.name,
                    rate: `${currencySymbol}${new Intl.NumberFormat(
                      'en-GB'
                    ).format(rate.rate)}`,
                  }))}
                  headerMapper={(text: string) => headerMapping[text]}
                  onClickRow={() => {}}
                  onPageChange={() => {}}
                  showPagination={false}
                  totalPages={1}
                  totalResults={1}
                  currentPage={0}
                  itemsPerPage={role?.defaultRates?.length}
                />
              ) : (
                <p>No rates for this currency</p>
              ),
          };
        })
      : [];

  useEffect(() => {
    fetch({ variables: { input: { id } } });
  }, [id]);

  const handleArchive = async () => {
    await saveRole({
      variables: {
        id: role?.id,
        input: {
          name: role?.name,
          description: role?.description,
          enabled: role?.enabled,
          defaultRates: role?.defaultRates,
          archivedAt: !archived ? new Date() : null,
        },
      },
    });
    fetch();
  };

  return (
    <>
      <Modal
        showModal={showArchiveConfirmationModal}
        completeButtonText={archived ? 'Unarchive' : 'Archive'}
        setShowModal={setShowArchiveConfirmationModal}
        title={`Confirm ${archived ? 'Unarchive' : 'Archive'} Role`}
        onComplete={async () => {
          await handleArchive();
          setShowArchiveConfirmationModal(false);
        }}
      >
        <div className="px-4 py-4">
          {archived
            ? 'Unarchive this role?'
            : 'Are you sure you want to archive this role? You can un-archive it later if needed'}
        </div>
      </Modal>
      <div className="bg-gray-50">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="flex flex-col md:items-center md:flex-row w-full md:justify-between px-4 py-5 sm:px-6 flex-wrap">
            <div className="flex flex-col">
              <h1 className="text-3xl font-extrabold text-gray-900 flex items-center gap-x-2">
                {role?.name} {archived ? <OrangeBadge text="Archived" /> : null}
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Created: {moment.unix(role?.createdAt / 1000).fromNow()}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Updated: {moment.unix(role?.updatedAt / 1000).fromNow()}
              </p>
            </div>
            <div className="flex gap-2">
              <Button
                text={archived ? 'Unarchive' : 'Archive'}
                className={
                  archived
                    ? 'bg-gray-800 hover:bg-black'
                    : 'bg-orange-400 hover:bg-orange-500'
                }
                onClick={() => setShowArchiveConfirmationModal(true)}
              />
              {userProfile?.permissions.includes(Permission.UpdateRoles) ? (
                <div>
                  <Button
                    onClick={() => navigate(`/labour/roles/${id}/edit`)}
                    type="submit"
                    text="Edit"
                  />
                </div>
              ) : null}
            </div>
          </div>
          {loading && (
            <div className="h-64 flex items-center justify-center">
              <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
            </div>
          )}
          {error && (
            <p className="px-4 py-5 sm:p-6 text-red-500">
              Error loading data. Please try again.
            </p>
          )}
          {role?.description ? (
            <p className="px-4 py-5 sm:px-6 ">{role?.description}</p>
          ) : null}
          {role && financeModuleEnabled ? (
            <div className="border-t border-gray-200">
              {sections.map((section, index) => (
                <div key={index} className="px-4 py-4">
                  <h2 className="text-lg font-medium text-gray-900">
                    {section.title}
                  </h2>
                  <p className="mt-1 text-sm text-gray-500">
                    {section.content}
                  </p>
                </div>
              ))}
            </div>
          ) : !role ? (
            <p>Role not found</p>
          ) : null}
        </div>
      </div>
    </>
  );
};

export const Role = () => <RolePage />;
