import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  GET_CERTIFICATIONS,
  GET_CERTIFICATION_ATTACHMENTS,
  SEARCH_CERTIFICATION_TYPES,
} from '../../../../graphql/queries/certifications';
import { useOrganisationAwareApollo } from '../../../../hooks/useOrganisationAwareApollo';
import {
  DELETE_CERTIFICATION_ATTACHMENT,
  SAVE_ATTACHMENT,
} from '../../../../graphql/mutations/attachments';
import {
  DELETE_CERTIFICATION,
  SAVE_CERTIFICATION,
} from '../../../../graphql/mutations/certifications';
import { saveFileToS3 } from '../../../../utils/utils';
import { LabourHeading } from '../../heading/labour';
import {
  PaginationProvider,
  usePagination,
} from '../../../../context/PaginationContext';
import { Table } from '../../../../components/Tables/tables/Table';
import moment from 'moment';
import { Button } from '../../../../components/Buttons/Button';
import { GET_CERTIFICATION_ATTACHMENT_URL } from '../../../../graphql/queries/attachments';
import { UserProfileContext } from '../../../../context/UserProfileContext';
import { YellowBadge } from '../../../../components/Badges/Badges';
import { Permission } from '../../../../types/Permissions';
import { useToast } from '../../../../context/ToastContext';
import { CertificationForm } from './CertificationForm';
import { DeleteButton } from '../../../../components/Deletion/DeleteButton';

const headerMapping = {
  name: 'File Name',
  id: 'ID',
  download: 'Download',
  updatedAt: 'Updated At',
} as Record<string, any>;

const SaveCertificationPage = () => {
  let { id, labourResourceId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const { useQuery, useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const isUpdate = !!id;

  const [deleteCertification] = useMutation(DELETE_CERTIFICATION);

  const { data, refetch } = useQuery(GET_CERTIFICATIONS, {
    variables: { input: { id } },
    skip: !isUpdate,
  });
  const [searchCertificationTypes, { data: certificationtypesData }] =
    useLazyQuery(SEARCH_CERTIFICATION_TYPES);

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
    }
  }, [id, refetch, isUpdate]);

  const { limit, offset, setOffset } = usePagination();

  const [certification] = data?.getCertifications?.results || [];

  const { userProfile } = useContext(UserProfileContext);

  const { addToast } = useToast();

  const [showDeleteCertificationModal, setShowDeleteCertificationModal] =
    useState(false);
  const [deletionFocus, setDeletionFocus] = useState('');

  const [getCertificationAttachmentURL, { data: certificationAttachmentURL }] =
    useLazyQuery(GET_CERTIFICATION_ATTACHMENT_URL);

  const [fetchAttachmentsForThisCertification, { data: attachmentsData }] =
    useLazyQuery(GET_CERTIFICATION_ATTACHMENTS);

  const [deleteCertificationAttachment] = useMutation(
    DELETE_CERTIFICATION_ATTACHMENT
  );

  useEffect(() => {
    if (certification) {
      searchCertificationTypes({
        variables: { searchTerm: certification?.certificationType?.name },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certification]);

  useEffect(() => {
    if (certification) {
      fetchAttachmentsForThisCertification({
        variables: {
          certificationId: certification.id,
          input: { limit, offset },
        },
        fetchPolicy: 'network-only',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certification, limit, offset]);

  const totalPages = Math.ceil(
    attachmentsData?.getCertificationAttachments?.count / limit
  );

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);
  const [saveCertification] = useMutation(SAVE_CERTIFICATION);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const performCertificationTypeSearch = async () => {
    try {
      await searchCertificationTypes({
        variables: {
          searchTerm: debouncedSearchTerm,
        },
        fetchPolicy: 'network-only',
      });
    } catch (err) {
      // do nothing
    } finally {
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performCertificationTypeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (
      attachmentsData &&
      attachmentsData.getCertificationAttachments.results.length > 0
    ) {
      const firstAttachmentId =
        attachmentsData.getCertificationAttachments.results[0].id;
      getCertificationAttachmentURL({
        variables: { input: { id: firstAttachmentId } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsData]);

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (id: any, value: any) => void
  ) => {
    try {
      let fileSaved;
      let newAttachmentId;
      if (values?.file?.name) {
        const { data } = await saveAttachment({
          variables: {
            input: {
              name: values.importName,
              id: null,
              fileName: values.file.name,
            },
          },
        });

        const presignedUrl = data?.saveAttachment?.presignedUrl;

        fileSaved = await saveFileToS3(values.file, presignedUrl);
        newAttachmentId = data?.saveAttachment?.id;
      }
      if (!values?.file?.name || (values?.file?.name && fileSaved)) {
        const { data } = await saveCertification({
          variables: {
            id: values?.id,
            input: {
              ...values,
              labourResourceId,
              file: undefined,
              newAttachmentId,
              issueDate: values?.issueDate
                ? String(values?.issueDate)
                : undefined,
              expiryDate: values?.expiryDate
                ? String(values?.expiryDate)
                : undefined,
              id: undefined,
              updatedAt: undefined,
              createdAt: undefined,
            },
          },
        });
        setSubmitting(false);
        if (data.saveCertification.success) {
          addToast('Saving certification successful', 'success');
          if (!id || (id && !newAttachmentId)) {
            navigate(
              location.pathname.split('/certifications')?.[0] +
                `?tab=Certifications`
            );
          } else {
            await fetchAttachmentsForThisCertification({
              variables: { certificationId: id, input: { limit, offset } },
              fetchPolicy: 'network-only',
            });
            if (setFieldValue) {
              setFieldValue('file', undefined);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const docs = [
    {
      uri: certificationAttachmentURL?.getCertificationAttachmentURL
        ?.presignedUrl,
      fileType:
        attachmentsData?.getCertificationAttachments?.results[0]?.fileName?.split(
          '.'
        )[1],
    },
  ];

  const downloadAttachment = async (id: string, fileName: string) => {
    const { data } = await getCertificationAttachmentURL({
      variables: { input: { id } },
    });
    if (data.getCertificationAttachmentURL.presignedUrl) {
      // Create a new link
      const anchor = document.createElement('a');
      anchor.href = data.getCertificationAttachmentURL.presignedUrl;
      anchor.download = fileName;
      anchor.style.display = 'none';

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);

      // Revoke the Blob URL to free up resources
      URL.revokeObjectURL(data.getCertificationAttachmentURL.presignedUrl);
    }
  };

  const hasDeletePermission = userProfile?.permissions?.includes(
    Permission.DeleteCertifications
  );

  return (
    <>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <LabourHeading
        id={labourResourceId}
        labourType={
          location.pathname.includes('subcontractors')
            ? 'subcontractors'
            : 'employees'
        }
        showMissingCertifications={true}
      />

      <CertificationForm
        certification={certification}
        presignedAttachmentUrl={
          certificationAttachmentURL?.getCertificationAttachmentURL
            ?.presignedUrl
        }
        certificationTypes={
          certificationtypesData?.searchCertificationTypes?.results
        }
        docs={docs}
        onDelete={
          isUpdate && hasDeletePermission
            ? async () => {
                try {
                  const { data } = await deleteCertification({
                    variables: { id: id },
                  });

                  if (data.deleteCertification.success) {
                    addToast('Deleting certification successful', 'success');

                    navigate(
                      location.pathname.split('/certifications')?.[0] +
                        `?tab=Certifications`
                    );
                  } else {
                    addToast('Error deleting certification', 'error');
                  }
                } catch (error) {
                  addToast('Error deleting certification', 'error');
                  console.error(error);
                }
              }
            : undefined
        }
        onSubmit={onSubmit}
        setSearchTerm={setSearchTerm}
      />
      {id ? (
        <div>
          <h1 className="my-2">Attachments</h1>
          <Table
            currentPage={Math.floor(offset / limit) + 1}
            totalPages={totalPages}
            totalResults={
              attachmentsData?.getCertificationAttachments?.count || 0
            }
            itemsPerPage={limit}
            onClickRow={(id) => {}}
            columnsToFilter={[]}
            columnValueMapper={{
              updatedAt: (text: string) =>
                moment.unix(parseInt(text) / 1000).fromNow(),
            }}
            headerMapper={(text: string) => headerMapping[text]}
            onPageChange={handlePageChange}
            data={
              attachmentsData?.getCertificationAttachments?.results.length > 0
                ? attachmentsData?.getCertificationAttachments?.results?.map(
                    (result: any) => ({
                      id: result?.id,
                      name: result?.fileName,
                      updatedAt: result?.updatedAt,
                      download: userProfile?.permissions.includes(
                        Permission.DownloadCertificationAttachments
                      ) ? (
                        <div className="flex flex-row gap-x-2">
                          <Button
                            text={'Download'}
                            onClick={async () =>
                              await downloadAttachment(
                                result.id,
                                result?.fileName
                              )
                            }
                          />
                          {userProfile?.permissions?.includes(
                            Permission.DeleteCertificationAttachments
                          ) ? (
                            <DeleteButton
                              isDisabled={!isUpdate}
                              modalText="Are you sure you want to delete this attachment? You will not be able to restore it once deleted."
                              modalTitle={'Delete Attachment'}
                              onClick={() => {
                                setDeletionFocus(result.id);
                              }}
                              onDelete={async () => {
                                await deleteCertificationAttachment({
                                  variables: { id: deletionFocus },
                                });
                                await fetchAttachmentsForThisCertification({
                                  variables: {
                                    certificationId: id,
                                    input: { limit, offset },
                                  },
                                  fetchPolicy: 'network-only',
                                });
                              }}
                            >
                              Delete
                            </DeleteButton>
                          ) : null}
                        </div>
                      ) : (
                        <YellowBadge text="Not Available" />
                      ),
                    })
                  )
                : []
            }
          />
        </div>
      ) : null}
    </>
  );
};

export const SaveCertification = () => (
  <PaginationProvider>
    <SaveCertificationPage />
  </PaginationProvider>
);
