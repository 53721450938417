import { useEffect, useState } from 'react';

export default function useEventListener(
  eventType: string,
  callback: (event: any) => void,
  element: any
) {
  useEffect(() => {
    let elementToListen = element;

    // This needs to be inside the useEffect, as document will be undefined during SSR
    if (!elementToListen) {
      elementToListen = document;
    }

    elementToListen.addEventListener(eventType, callback);
    return () => elementToListen.removeEventListener(eventType, callback);
  }, [eventType, element, callback]);
}

export const tailwindBreakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1536px',
};

export function getMaxWidthMediaQuery(breakpoint: string) {
  return `(max-width: ${breakpoint})`;
}

export function useMediaQuery(mediaQuery: string) {
  const [isMatch, setIsMatch] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState<
    MediaQueryList | undefined
  >(undefined);

  useEffect(() => {
    const list = window?.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list?.matches);
  }, [mediaQuery]);

  useEventListener('change', (e) => setIsMatch(e.matches), mediaQueryList);

  return isMatch;
}

// This is the opposite behaviour to tailwind, this will return true when the screen is smaller than the breakpoint
export function useIsSmallScreenOrSmaller() {
  return useMediaQuery(getMaxWidthMediaQuery(tailwindBreakpoints.sm));
}

export function useIsMediumScreenOrSmaller() {
  return useMediaQuery(getMaxWidthMediaQuery(tailwindBreakpoints.md));
}

export function useIsLargeScreenOrSmaller() {
  return useMediaQuery(getMaxWidthMediaQuery(tailwindBreakpoints.lg));
}

export function useIsXlScreenOrSmaller() {
  return useMediaQuery(getMaxWidthMediaQuery(tailwindBreakpoints.xl));
}

export function useIsXxlScreenOrSmaller() {
  return useMediaQuery(getMaxWidthMediaQuery(tailwindBreakpoints.xxl));
}
