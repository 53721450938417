import { ClipboardDocumentIcon } from '@heroicons/react/20/solid';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import {
  DateTimePicker,
  DateTimePickerSelectorType,
} from 'react-datetime-pickers';
import 'react-datetime-pickers/dist/index.css';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ApprovedBadge, YellowBadge } from '../../../components/Badges/Badges';
import { Button } from '../../../components/Buttons/Button';
import Switch from '../../../components/Inputs/Switch';
import {
  PaginationProvider,
  usePagination,
} from '../../../context/PaginationContext';
import { UserProfileContext } from '../../../context/UserProfileContext';
import {
  APPROVE_TIMESHEET,
  SAVE_TIMESHEET_SUBMISSION,
} from '../../../graphql/mutations/timesheet-submissions';
import { SEARCH_PROJECTS } from '../../../graphql/queries/projects';
import {
  GET_MY_PROJECT_TIMESHEET_ENTRIES,
  GET_PROJECT_ASSIGNMENTS_WITH_TIMESHEET_ENTRIES,
} from '../../../graphql/queries/timesheet-entries';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { Permission } from '../../../types/Permissions';
import { BackToTimesheetsButton } from './buttons/BackToTimesheets';
import { ClearProjectButton } from './buttons/ClearProjectButton';
import { ActivityCodeInput } from './codes/inputs';
import { ActivityCodeDisplay } from './codes/list';
import { CustomDataOptionsInput } from './customData/inputs';
import { createAssigneesArray } from './data/assignees';
import { createInitialEntry } from './data/entry';
import { renderErrorMessages } from './errors';
import { Navigation } from './nav';
import './overrides.css';
import { EntryLevelProjectSelection, ProjectSelection } from './project/search';
import {
  validateCodingsPerEntry,
  validateCodingsTotal,
} from './validation/validation';
import { alignDateWithWeekId } from '../../../utils/dateUtils';
import { useToast } from '../../../context/ToastContext';

interface TimesheetFormProps {
  configurationId?: string;
  weekId?: string;
  submissionId?: string;
  timesheetConfig: Record<string, any>;
  customDataOptions: any[];
  multiProject: boolean;
  selectedDate?: Date;
  labourResource?: {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  selectedProject?: {
    id: string;
    name: string;
    internalId: string;
  };
  setSelectedDate: (date: Date) => void;
  setSelectedProject: (project: any) => void;
  refetchSubmission?: () => Promise<void>;
  timesheetApproval?: {
    id: string;
    approved: boolean;
    approvedBy?: string;
    submitted: boolean;
    amendmentsRequested: boolean;
    amendmentsReason: boolean;
  };
}

const TimesheetForm = ({
  configurationId,
  weekId,
  submissionId,
  timesheetConfig,
  customDataOptions,
  timesheetApproval,
  selectedProject,
  selectedDate,
  labourResource,
  setSelectedProject,
  setSelectedDate,
  refetchSubmission,
  multiProject,
}: TimesheetFormProps) => {
  const navigate = useNavigate();

  const { limit, offset, setOffset } = usePagination();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const [searchTerm, setSearchTerm] = useState('');
  const [showDisabled, setShowDisabled] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [fetchAssignmentTimesheetEntries, { data }] = useLazyQuery(
    GET_PROJECT_ASSIGNMENTS_WITH_TIMESHEET_ENTRIES,
    {
      variables: {
        projectId: selectedProject?.id,
        ...(submissionId
          ? { submissionId }
          : {
              weekId,
              configurationId,
            }),

        showDisabled,
        input: { limit, offset },
      },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchMyProjectTimesheetEntries, { data: projectTimesheetEntries }] =
    useLazyQuery(GET_MY_PROJECT_TIMESHEET_ENTRIES, {
      variables: {
        ...(submissionId
          ? { submissionId }
          : {
              weekId,
              configurationId,
            }),
        input: { limit, offset },
      },
      fetchPolicy: 'network-only',
    });

  const [fetchProjects, { data: projectsData }] = useLazyQuery(
    SEARCH_PROJECTS,
    {
      variables: { searchTerm: debouncedSearchTerm },
      fetchPolicy: 'network-only',
    }
  );

  const [saveTimesheetSubmission, { loading: isSaving }] = useMutation(
    SAVE_TIMESHEET_SUBMISSION
  );

  const [approveTimesheet, { loading: isSavingApprovalStatus }] =
    useMutation(APPROVE_TIMESHEET);

  const handlePageChange = (pageNumber: number) => {
    setOffset((pageNumber - 1) * limit);
  };

  const fetchTimesheetData = async () => {
    if (!multiProject && selectedProject?.id) {
      await fetchAssignmentTimesheetEntries({
        variables: {
          ...(submissionId
            ? { submissionId }
            : { projectId: selectedProject?.id }),
          input: { limit, offset },
        },
        fetchPolicy: 'network-only',
      });
    } else if (multiProject) {
      await fetchMyProjectTimesheetEntries({
        variables: {
          ...(submissionId ? { submissionId } : {}),
          input: { limit, offset },
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  useEffect(() => {
    setOffset(0);
  }, [selectedProject?.id, selectedDate, setOffset]);

  useEffect(() => {
    fetchTimesheetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProject?.id,
    limit,
    offset,
    selectedDate,
    multiProject,
    submissionId,
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 600); // 300ms debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    performSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const projectAssignmentResults = data?.getAssignmentsWithTimesheetEntries;
  const projectTimesheetEntryResults =
    projectTimesheetEntries?.getMyProjectTimesheetEntries;

  const assignees = createAssigneesArray(projectAssignmentResults?.results);

  const realignedDate = alignDateWithWeekId(weekId);

  const initialValues = {
    weekId,
    configurationId,
    entries:
      (multiProject
        ? projectTimesheetEntryResults?.results?.map((results: any) =>
            createInitialEntry({
              timesheetConfig,
              id: results?.id,
              projectId: results?.project?.id,
              projectName: results?.project?.name,
              days: results?.days,
              customData: results?.customData.reduce(
                (accumulator: any, current: any) => {
                  accumulator[current.key] = current.value;
                  return accumulator;
                },
                {}
              ),
            })
          )
        : assignees?.map((assignee) =>
            createInitialEntry({
              timesheetConfig,
              id: assignee.entryId,
              assigneeId: assignee?.id,
              assigneeName: assignee.name,
              projectId: selectedProject?.id,
              roleName: assignee.roleName,
              days: assignee?.days,
              disabled: assignee?.disabled,
              customData: assignee?.customData?.reduce(
                (accumulator: any, current: any) => {
                  accumulator[current.key] = current.value;
                  return accumulator;
                },
                {}
              ),
            })
          )) || [],
  };

  const validationSchema = Yup.object().shape({
    entries: Yup.array()
      .of(
        Yup.object().shape({
          assigneeId: Yup.string(),
          projectId: Yup.string().required('Project is required'),
          days: Yup.array().of(
            Yup.object().shape({
              day: Yup.string().required('Day is required'),
              codings:
                timesheetConfig?.granularity !== 'DAILY'
                  ? Yup.object().shape({})
                  : Yup.object().shape({}),
              selectedCode: Yup.string().nullable(),
            })
          ),
        })
      )
      .test(
        'validate-codings-total',
        'Coding totals for a day exceed the maximum limit',
        function (value) {
          if (
            timesheetConfig.granularity?.toLowerCase() === 'day_percentage' ||
            (timesheetConfig.granularity?.toLowerCase() === 'hourly' &&
              multiProject)
          ) {
            const errorMsg = validateCodingsTotal(
              value,
              timesheetConfig.granularity
            );
            return errorMsg ? this.createError({ message: errorMsg }) : true;
          }
          if (
            timesheetConfig.granularity?.toLowerCase() === 'hourly' &&
            value
          ) {
            let errorMsg = null;
            for (let entry of value) {
              errorMsg = validateCodingsPerEntry(entry);
              if (errorMsg) {
                return errorMsg
                  ? this.createError({ message: errorMsg })
                  : true;
              }
            }
          }
          return true;
        }
      ),
  });

  const { userProfile } = useContext(UserProfileContext);

  const canApproveTimesheet =
    userProfile?.permissions?.includes(Permission.ApproveTimesheets) &&
    (!timesheetConfig?.approvalGroups?.length ||
      userProfile?.groups?.some((group) =>
        timesheetConfig?.approvalGroups?.includes(group)
      ));

  const canUnapproveTimesheet =
    userProfile?.permissions?.includes(Permission.UnapproveTimesheets) &&
    (!timesheetConfig?.approvalGroups?.length ||
      userProfile?.groups?.some((group) =>
        timesheetConfig.approvalGroups.includes(group)
      ));

  const projectSearchResults = projectsData?.searchProjects?.results || [];

  const performSearch = async () => {
    try {
      await fetchProjects({ variables: { searchTerm: debouncedSearchTerm } });
    } catch (err) {
      // do nothing
    }
  };

  const minDate = moment().subtract(12, 'weeks').toDate();
  const maxDate = moment().add(4, 'weeks').toDate();

  const currentPage = Math.floor(offset / limit) + 1;
  const totalPages = Math.ceil((projectAssignmentResults?.count || 0) / limit);

  const { addToast } = useToast();

  const handleSubmit = async (
    values: Record<string, any>,
    submitted?: boolean
  ) => {
    try {
      await saveTimesheetSubmission({
        variables: {
          input: {
            ...(submissionId
              ? { submissionId }
              : {
                  weekId: values?.weekId,
                  configurationId,
                }),
            weekCommencingDate: realignedDate
              ? realignedDate?.toISOString()
              : null,
            projectId: selectedProject?.id,
            submitted,
            entries: values?.entries?.map((entry: any) => ({
              id: entry?.id,
              projectId: entry.projectId,
              assignmentRoleId: entry.assigneeId,
              delete: entry?.delete,
              days: entry.days.map((dayEntry: any) => ({
                day: dayEntry?.day,
                selectedCode: timesheetConfig?.activityCodes.find(
                  (code: any) => code?.id === dayEntry?.selectedCode
                )?.id,
                codings: dayEntry?.codings
                  ? Object.entries(dayEntry.codings).map(([key, value]) => ({
                      key,
                      value:
                        value && !isNaN(value as any) ? `${value}` : undefined,
                    }))
                  : [],
              })),
              customData:
                entry?.customData && Object.keys(entry.customData).length > 0
                  ? Object.entries(entry.customData).map(([key, value]) => ({
                      key,
                      value,
                    }))
                  : [],
            })),
          },
        },
      });
      await fetchTimesheetData();
      refetchSubmission && (await refetchSubmission());
      addToast('Timesheet saved successfully', 'success');
    } catch (err) {
      addToast('Error saving timesheet', 'error');
    }
  };

  const FocusedWeek = () => {
    return realignedDate ? (
      <div
        data-testid="week-container"
        className="flex items-center sm:justify-end my-2"
      >
        <p className="text-gray-400 text-sm">Week: </p>
        <p className="ml-1 flex items-center">
          {moment(realignedDate).isoWeek()}{' '}
          <p className="text-gray-400 mx-1 text-sm">/</p>{' '}
          {moment(realignedDate).isoWeekYear()}
        </p>
      </div>
    ) : null;
  };

  const copiedDays = useRef(null);

  const tooltipRefs = useRef([]);

  const handleCopy = (days: any[], entryIndex: number) => {
    // @ts-ignore
    copiedDays.current = [...days];
    if (tooltipRefs.current[entryIndex]) {
      // @ts-ignore
      tooltipRefs.current[entryIndex].style.visibility = 'visible';
      setTimeout(() => {
        if (tooltipRefs.current[entryIndex]) {
          // @ts-ignore
          tooltipRefs.current[entryIndex].style.visibility = 'hidden';
        }
      }, 1500);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values) => await handleSubmit(values)}
    >
      {({ values, setFieldValue, submitForm, dirty, isValid, errors }) => {
        const handlePaste = (entryIndex: number) => {
          if (!copiedDays.current) return;

          const updatedEntries = [...values.entries];
          updatedEntries[entryIndex].days = [...copiedDays.current];
          setFieldValue('entries', updatedEntries);
        };
        return (
          <div data-testid="timesheet-form" className="w-full mb-48">
            <div className="rounded-md shadow-md bg-white pb-2 my-4">
              <div className="border-b bg-black rounded-t-md pt-1 pb-1">
                <BackToTimesheetsButton />
              </div>
              <div className="px-6">
                <h1 className="mt-4 text-base">{timesheetConfig?.name}</h1>
                {timesheetConfig?.description ? (
                  <h3 className="text-sm text-gray-500 mt-1">
                    {timesheetConfig?.description}
                  </h3>
                ) : null}
                {!timesheetApproval?.submitted &&
                timesheetApproval?.id &&
                !timesheetApproval.approved ? (
                  <div className="bg-yellow-50 border-l-4 border-yellow-500 rounded-md text-yellow-700 p-4 mt-4">
                    <p className="text-sm">
                      This timesheet has been saved but not yet submitted for
                      approval.
                    </p>
                  </div>
                ) : timesheetApproval?.submitted &&
                  !timesheetApproval.approved ? (
                  <div className="bg-purple-50 border-l-4 border-purple-500 rounded-md text-purple-800 p-4 mt-4">
                    <p className="text-sm">
                      This timesheet has been submitted for approval.
                    </p>
                  </div>
                ) : null}
              </div>
              <Form className="px-6 pb-2">
                <div className="flex my-4 items-center justify-between w-full gap-y-4 flex-wrap">
                  <FocusedWeek />
                  {!multiProject && !submissionId && (
                    <ProjectSelection
                      projectSearchResults={projectSearchResults}
                      setSearchTerm={setSearchTerm}
                      setSelectedProject={setSelectedProject}
                      selectedProject={selectedProject}
                    />
                  )}
                  {!submissionId ? (
                    <div
                      className="flex items-center flex-col justify-center gap-y-2"
                      data-testid="datetime-picker"
                    >
                      <p className="text-sm text-gray-600">Select a Week:</p>
                      <div className="border rounded-md">
                        <DateTimePicker
                          minDate={minDate}
                          maxDate={maxDate}
                          selector={DateTimePickerSelectorType.WEEK}
                          onChange={async (date: any) => {
                            if (dirty) {
                              await submitForm();
                              setSelectedDate(date);
                            } else {
                              setSelectedDate(date);
                            }
                          }}
                          selected={selectedDate}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                {multiProject && !timesheetApproval?.approved ? (
                  <div className="flex w-full justify-end">
                    <Button
                      text="Add Row"
                      // @ts-ignore
                      onClick={() =>
                        setFieldValue('entries', [
                          ...values.entries,
                          createInitialEntry({
                            timesheetConfig,
                            unsaved: true,
                          }),
                        ])
                      }
                    />
                  </div>
                ) : null}
                <div style={{ zIndex: 9 }} className="xl:sticky xl:top-[70px]">
                  {!multiProject && selectedProject?.name ? (
                    <>
                      <div className="text-base bg-white border-b border-t border-dashed py-4 px-2 flex flex-row items-center justify-between">
                        <p>
                          {selectedProject?.name} -{' '}
                          {selectedProject?.internalId}
                        </p>
                        <FocusedWeek />
                      </div>
                    </>
                  ) : submissionId && labourResource?.id ? (
                    <div className="text-base mt-5 bg-white border-b border-t border-dashed py-4 px-2 flex flex-row items-center justify-between">
                      <div className="flex flex-col">
                        <p>
                          {labourResource?.firstName} {labourResource?.lastName}
                        </p>
                        <p className="text-xs text-gray-600">
                          {labourResource?.email}
                        </p>
                      </div>
                      <FocusedWeek />
                    </div>
                  ) : null}
                  <ActivityCodeDisplay
                    activityCodes={timesheetConfig?.activityCodes}
                    showOnDefault={!timesheetApproval?.approved}
                  />
                  {timesheetApproval?.approved ? (
                    <div className="px-4 py-2 text-sm bg-gray-50 border-b flex items-center gap-x-2">
                      <ApprovedBadge />
                      This Timesheet has been approved
                      {timesheetApproval.approvedBy
                        ? ` by ${timesheetApproval.approvedBy} `
                        : ' '}
                      and cannot be edited.
                    </div>
                  ) : !timesheetApproval?.approved &&
                    timesheetApproval?.submitted ? (
                    <div className="px-4 py-2 text-sm bg-gray-50 border-b flex items-center gap-x-2">
                      <YellowBadge text="Unapproved" />
                      This Timesheet has not yet been approved.
                    </div>
                  ) : null}
                </div>
                {!multiProject && selectedProject?.name ? (
                  <div className="pt-4 px-4">
                    <Switch
                      text="Show Inactive Assignees"
                      enabled={showDisabled}
                      handleChange={() => {
                        setShowDisabled(!showDisabled);
                        setOffset(0);
                      }}
                    />
                  </div>
                ) : null}
                <div className="mt-4">
                  <FieldArray
                    name="entries"
                    render={() =>
                      values.entries
                        ?.filter(
                          (entry: any) => !(entry.delete && entry.unsaved)
                        )
                        ?.map((entry: any, entryIndex: number) => (
                          <>
                            <div
                              data-testid="time-entry-row"
                              style={entry.delete ? { opacity: 0.25 } : {}}
                              key={`${entry.id}`}
                              className="w-full py-2"
                            >
                              <div
                                className={`bg-white border rounded-t-lg px-4 py-2`}
                              >
                                {multiProject ? (
                                  <ClearProjectButton
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    entry={entry}
                                    disabled={timesheetApproval?.approved}
                                  />
                                ) : null}
                                <div className="flex items-center justify-between">
                                  {!timesheetConfig?.trackOwnTime ? (
                                    <>
                                      <div className="flex flex-row w-full justify-between">
                                        <p className="text-sm text-black flex gap-x-2 items-center">
                                          <Avatar
                                            name={entry.assigneeName}
                                            size="25"
                                            round
                                          />
                                          {entry.assigneeName} -{' '}
                                          {entry.roleName}
                                          {entry.disabled ? ' (Inactive) ' : ''}
                                        </p>
                                        <div className="flex gap-x-2 relative">
                                          {/* @ts-ignore */}
                                          <div
                                            className="text-sm"
                                            ref={(el) =>
                                              // @ts-ignore
                                              (tooltipRefs.current[entryIndex] =
                                                el)
                                            }
                                            style={{
                                              visibility: 'hidden',
                                              position: 'absolute',
                                              top: '100%',
                                              marginTop: 5,
                                              left: 0,
                                              backgroundColor: 'white',
                                              color: 'black',
                                              padding: '8px',
                                              borderRadius: '5px',
                                            }}
                                          >
                                            Copied! ✨
                                          </div>
                                          <Button
                                            text={
                                              <div className="flex justify-center gap-x-2 items-center">
                                                <ClipboardDocumentIcon
                                                  className="h-5 w-5 text-white"
                                                  aria-hidden="true"
                                                />
                                                Copy
                                              </div>
                                            }
                                            onClick={() =>
                                              handleCopy(entry.days, entryIndex)
                                            }
                                          />
                                          <Button
                                            text={
                                              <div className="flex justify-center gap-x-2 items-center">
                                                <ClipboardIcon
                                                  className="h-5 w-5 text-white"
                                                  aria-hidden="true"
                                                />
                                                Paste
                                              </div>
                                            }
                                            onClick={() =>
                                              handlePaste(entryIndex)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {multiProject &&
                                  !values.entries?.[entryIndex].projectId ? (
                                    <EntryLevelProjectSelection
                                      projectSearchResults={
                                        projectSearchResults
                                      }
                                      setSearchTerm={setSearchTerm}
                                      setFieldValue={setFieldValue}
                                      entryIndex={entryIndex}
                                      values={values}
                                    />
                                  ) : null}
                                  {multiProject &&
                                  values.entries?.[entryIndex].projectName ? (
                                    <p className="text-sm">
                                      {values.entries?.[entryIndex].projectName}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="flex w-full flex-wrap 2xl:flex-nowrap">
                                {entry.days.map((day: any, dayIndex: any) => (
                                  <ActivityCodeInput
                                    day={day}
                                    dayIndex={dayIndex}
                                    timesheetConfig={timesheetConfig}
                                    selectedDate={realignedDate}
                                    setFieldValue={setFieldValue}
                                    entryIndex={entryIndex}
                                    values={values}
                                    multiProject={multiProject}
                                    entry={entry}
                                    disabled={timesheetApproval?.approved}
                                  />
                                ))}
                              </div>
                              <CustomDataOptionsInput
                                customDataOptions={customDataOptions}
                                setFieldValue={setFieldValue}
                                entryIndex={entryIndex}
                                entry={entry}
                                values={values}
                                disabled={timesheetApproval?.approved}
                              />
                            </div>
                          </>
                        ))
                    }
                  />
                </div>
              </Form>
            </div>

            <div className="sticky bottom-0 rounded-lg shadow-sm bg-white box-border w-full py-2">
              {renderErrorMessages(errors)}
              <div className="border-t flex flex-col py-2 pt-4 gap-x-2 gap-y-4 mx-12 items-center justify-center md:items-end md:justify-end px-2">
                <Navigation
                  currentPage={currentPage}
                  totalPages={totalPages}
                  isSaving={isSaving || isSavingApprovalStatus}
                  isValid={isValid}
                  submitForm={submitForm}
                  handlePageChange={handlePageChange}
                  values={values}
                  handleSubmit={handleSubmit}
                  navigate={navigate}
                  isSubmitted={timesheetApproval?.submitted}
                  isApproved={timesheetApproval?.approved}
                  canApproveTimesheet={canApproveTimesheet}
                  canUnapproveTimesheet={canUnapproveTimesheet}
                  handleApproveTimesheet={approveTimesheet}
                  submissionId={submissionId}
                  refetch={refetchSubmission}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default TimesheetForm;

export const PaginatedTimesheetForm = (props: any) => (
  <PaginationProvider>
    <TimesheetForm {...props} />
  </PaginationProvider>
);
