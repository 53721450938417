import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';

export default function NotificationIcon({
  className,
  icon,
}: {
  className?: string;
  icon?: React.ReactNode;
}) {
  return (
    <div className={twMerge('relative h-5 w-5', className)}>
      <div className="h-5 w-5 absolute left-0 right-0 top-0 bottom-0 m-auto bg-red-500 rounded-full motion-safe:animate-ping-slow" />
      <div className="h-4 w-4 absolute left-0 right-0 top-0 bottom-0 m-auto bg-white rounded-full " />
      {icon ? (
        icon
      ) : (
        <ExclamationCircleIcon
          className="h-5 w-5 absolute left-0 right-0 top-0 bottom-0 m-auto text-red-500 rounded-full"
          aria-hidden="true"
        />
      )}
    </div>
  );
}
