import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import FormCard from '../../../components/FormCard/FormCard';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../types/Form';
import { GET_PROGRESS_ACTIVITY_CODES } from '../../../graphql/queries/progress-activity-submissions';
import { useToast } from '../../../context/ToastContext';
import {
  DELETE_PROGRESS_ACTIVITY_CODE,
  SAVE_PROGRESS_ACTIVITY_CODE,
} from '../../../graphql/mutations/progress-activity-submissions';
import { UserProfileContext } from '../../../context/UserProfileContext';
import { Permission } from '../../../types/Permissions';

export const SaveProgressActivityCode = () => {
  let { id, projectId } = useParams();
  const { userProfile } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const { useQuery, useMutation } = useOrganisationAwareApollo();

  const { addToast } = useToast();

  const { data } = useQuery(GET_PROGRESS_ACTIVITY_CODES, {
    variables: { projectId, input: { id } },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  const [saveProgressActivityCode] = useMutation(SAVE_PROGRESS_ACTIVITY_CODE);
  const [deleteProgressActivityCode] = useMutation(
    DELETE_PROGRESS_ACTIVITY_CODE
  );

  const [progressCode] = data?.getProgressActivityCodes?.results || [];

  const initialValues = id
    ? {
        id,
        projectId,
        code: progressCode?.code,
        description: progressCode?.description,
      }
    : { code: undefined, description: undefined };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveProgressActivityCode({
        variables: {
          id: values?.id,
          input: {
            ...values,
            projectId,
          },
        },
      });

      if (setSubmitting) {
        setSubmitting(false);
      }

      if (!data?.saveProgressActivityCode?.success) {
        if (
          data?.saveProgressActivityCode.message?.includes('already exists')
        ) {
          addToast(
            'This progress code already exists for this project',
            'error'
          );
        } else {
          addToast('Failed to save progress code', 'error');
        }
      } else {
        navigate(`/projects/${projectId}?tab=Progress%20Codes`);
        addToast('Progress code saved successfully', 'success');
      }
    } catch (error) {
      console.error(error);
      addToast('Failed to save progress code', 'error');
    }
  };

  const handleDeleteProgressCode = async () => {
    try {
      const { data } = await deleteProgressActivityCode({
        variables: {
          id: progressCode?.id,
        },
      });

      if (!data.deleteProgressActivityCode.success) {
        addToast('Failed to delete progress code', 'error');
      } else {
        addToast('Progress code deleted successfully', 'success');
        navigate(`/projects/${projectId}?tab=Progress%20Codes`);
      }
    } catch (error) {
      console.error(error);
      addToast('Failed to delete progress code', 'error');
    }
  };

  const formConfig = {
    formSections: [
      {
        title: 'Progress Code',
        description:
          'Basic details about the progress code. Ensure you put enough context in the description to explain what the code means.',
        fields: [
          {
            id: 'code',
            type: 'input',
            title: 'Progress Code',
            placeholder: 'Progress code',
          },
          {
            id: 'description',
            type: 'textarea',
            title: 'Description',
            placeholder: 'Description of the progress code',
          },
        ],
      },
    ],
  } as FormConfig;

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .max(255, 'Too Long!')
      .typeError('Required field')
      .required(),
  });

  return (
    <FormCard
      key={`${formConfig?.title}`}
      config={formConfig}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      entityName="Progress Code"
      onDelete={
        id &&
        userProfile?.permissions?.includes(
          Permission.DeleteProgressActivityCodes
        )
          ? handleDeleteProgressCode
          : undefined
      }
    />
  );
};
