export const isLocalhost = Boolean(
  window?.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window?.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window?.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const joinClassNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ');
};

export function truncate(string = '', maxLength = 100) {
  return string?.length > maxLength
    ? `${string.trim().substring(0, maxLength)}...`
    : string;
}

export function isEmptyOrUndefined(object: any) {
  if (!object) {
    return true;
  }

  if (typeof object === 'object') {
    return Object.keys(object).length === 0;
  }

  return false;
}

export const saveFileToS3 = async (
  file: any,
  presignedUrl: string
): Promise<boolean> => {
  // post the file to the url
  try {
    const myHeaders = new Headers({ 'Content-Type': 'text/csv' });
    await fetch(presignedUrl, {
      method: 'PUT',
      headers: myHeaders,
      // @ts-ignore
      body: file,
    });
    return true;
  } catch (error) {
    console.error('Error uploading logo:', error);
    return false;
  }
};

export function exponentialBackoff(func: any, maxDuration: any) {
  let delay = 1000;
  let totalDuration = 0;

  const attempt = () => {
    setTimeout(() => {
      func();
      totalDuration += delay;
      if (totalDuration + delay * 2 <= maxDuration) {
        delay *= 2;
        attempt();
      }
    }, delay);
  };

  attempt();
}

export const stripHtmlTags = (html: string): string => {
  return html ? html?.replace(/<\/?[^>]+(>|$)/g, '') : '';
};
