import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect } from 'react';

import FormCard from '../../../../../components/FormCard/FormCard';

import moment from 'moment';
import { Button } from '../../../../../components/Buttons/Button';
import {
  DELETE_ASSESSMENT_SUBMISSION,
  SAVE_ASSESSMENT_SUBMISSION,
} from '../../../../../graphql/mutations/assessments';
import { GET_ASSESSMENTS } from '../../../../../graphql/queries/assessments';
import { GET_PROJECT_ASSIGNMENT_ROLES } from '../../../../../graphql/queries/assignments';
import { useOrganisationAwareApollo } from '../../../../../hooks/useOrganisationAwareApollo';
import { FormConfig } from '../../../../../types/Form';
import { SAVE_ATTACHMENT } from '../../../../../graphql/mutations/attachments';
import { saveFileToS3 } from '../../../../../utils/utils';
import { AssessmentAttachmentsList } from '../../../../../components/assessments/AssessmentAttachmentsList';
import { UserProfileContext } from '../../../../../context/UserProfileContext';
import { Permission } from '../../../../../types/Permissions';
import { useToast } from '../../../../../context/ToastContext';

export const SaveAssessmentSubmission = () => {
  let { id, assignmentRoleId } = useParams();
  const navigate = useNavigate();
  const { useQuery, useMutation, useLazyQuery } = useOrganisationAwareApollo();

  const { userProfile } = useContext(UserProfileContext);

  const hasDeletePermission = userProfile?.permissions?.includes(
    Permission.DeleteAssessmentSubmissions
  );

  const isUpdate = !!id;

  const { data, refetch } = useQuery(GET_ASSESSMENTS, {
    variables: { input: { id } },
    skip: !isUpdate,
  });

  const { addToast } = useToast();

  const [saveAttachment] = useMutation(SAVE_ATTACHMENT);

  const [deleteAssessmentSubmission] = useMutation(
    DELETE_ASSESSMENT_SUBMISSION
  );

  const [fetchAssignmentRole, { data: assignmentData }] = useLazyQuery(
    GET_PROJECT_ASSIGNMENT_ROLES,
    {
      variables: { input: { id: assignmentRoleId } },
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (isUpdate) {
      refetch({ id });
      fetchAssignmentRole({ variables: { input: { id: assignmentRoleId } } });
    }
  }, [id, refetch, isUpdate, fetchAssignmentRole, assignmentRoleId]);

  const [assessmentSubmission] = data?.getAssessmentSubmissions?.results || [];

  const [saveAssessmentSubmission] = useMutation(SAVE_ASSESSMENT_SUBMISSION);

  const [assignmentRole] = assignmentData?.getAssignmentRoles?.results || [];

  const rateConfig = {
    formSections: [
      {
        title: assessmentSubmission?.assessmentType?.name,
        components: [
          <div className="bg-white flex flex-col px-6 border-b border-dashed py-4 rounded-md">
            <h1 className="text-3xl font-extrabold text-gray-900">
              {assignmentRole?.assignment?.lrs?.lastName},{' '}
              {assignmentRole?.assignment?.lrs?.firstName}
            </h1>
            <p className="mt-1 text-lg text-gray-900">
              Role: {assignmentRole?.role?.name}
            </p>
            <div className="flex text-gray-500 flex-col gap-y-1 py-2 my-2 border-dashed border-t border-b">
              <h2 className="text-sm">
                Created:{' '}
                <strong className="text-sm text-gray-800">
                  {moment
                    .unix(parseInt(assessmentSubmission?.createdAt) / 1000)
                    .fromNow()}
                </strong>
              </h2>
              <h2 className="text-sm">
                Updated:{' '}
                <strong className="text-sm text-gray-800">
                  {moment
                    .unix(parseInt(assessmentSubmission?.updatedAt) / 1000)
                    .fromNow()}
                </strong>
              </h2>
            </div>
          </div>,
          <div className="bg-white flex flex-col px-6 py-4 rounded-md shadow-md">
            {assessmentSubmission?.responses?.length > 0 ? (
              assessmentSubmission?.responses?.map((response: any) => (
                <div className="flex flex-col gap-y-1 py-2 border-t border-b border-dashed">
                  <h3 className="text-base text-gray-600">
                    {response?.question}
                  </h3>
                  <h4 className="text-base flex items-center gap-x-2">
                    <strong className="text-gray-700 text-sm">Answered:</strong>{' '}
                    {response?.answer ?? '(empty)'}
                  </h4>
                </div>
              ))
            ) : (
              <p>Nothing submitted yet.</p>
            )}
          </div>,
        ],
        fields: [
          {
            title: 'Outcome',
            id: 'outcome',
            type: 'dropdown',
            options: assessmentSubmission?.assessmentType?.outcomes?.map(
              (outcome: any) => ({ id: outcome, name: outcome })
            ),
          },
        ],
      },
    ],
  } as FormConfig;

  const initialValues = id ? assessmentSubmission : {};

  const validationSchema = Yup.object().shape({
    outcome: Yup.string().required('Outcome is required'),
  });

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    console.log(values);
    try {
      await saveAssessmentSubmission({
        variables: {
          id: values?.id,
          input: {
            labourResourceId: assignmentRole?.assignment?.lrs?.id,
            outcome: values?.outcome,
          },
        },
      });
      setSubmitting(false);
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveAttachment = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue?: (field: any, values: any) => void
  ) => {
    let newAttachmentId;
    if (values?.file?.name) {
      const { data } = await saveAttachment({
        variables: {
          input: {
            name: values.importName,
            id: null,
            fileName: values.file.name,
          },
        },
      });

      const presignedUrl = data?.saveAttachment?.presignedUrl;

      await saveFileToS3(values.file, presignedUrl);
      newAttachmentId = data?.saveAttachment?.id;
      await saveAssessmentSubmission({
        variables: {
          id: values?.id,
          input: {
            labourResourceId: assignmentRole?.assignment?.lrs?.id,
            newAttachmentId,
          },
        },
      });
    }
    setSubmitting(false);
    if (setFieldValue) {
      setFieldValue('file', undefined);
    }
  };

  const onDelete = async () => {
    try {
      const { data } = await deleteAssessmentSubmission({
        variables: {
          id: assessmentSubmission?.id,
        },
      });
      if (data.deleteAssessmentSubmission.success) {
        addToast('Assessment deleted', 'success');
        navigate(-1);
      } else {
        addToast('Error deleting assessment', 'error');
      }
    } catch (error) {
      addToast('Error deleting assessment', 'error');
      console.error(error);
    }
  };

  return (
    <>
      <div className="flex w-full border-b mb-2">
        <Button
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none',
            borderRadius: 0,
          }}
          onClick={() => navigate(-1)}
          type="submit"
          text={`< Go Back`}
        />
      </div>
      <FormCard
        key={`${rateConfig?.title}`}
        config={rateConfig}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        entityName="Assessment"
        {...(hasDeletePermission && {
          onDelete,
        })}
      />
      {assessmentSubmission && (
        <AssessmentAttachmentsList
          assessmentSubmission={assessmentSubmission}
          onSaveAttachment={onSaveAttachment}
        />
      )}
    </>
  );
};
