import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useContext, useEffect } from 'react';

import FormCard from '../../../components/FormCard/FormCard';

import { SAVE_GROUP } from '../../../graphql/mutations/users';
import { GET_GROUPS } from '../../../graphql/queries/users';
import { useOrganisationAwareApollo } from '../../../hooks/useOrganisationAwareApollo';
import { FieldType, FormConfig } from '../../../types/Form';
import { ActiveOrganisationContext } from '../../../context/ActiveOrganisationContext';
import { Permission } from '../../../types/Permissions';
import { useToast } from '../../../context/ToastContext';
interface PermissionItem {
  name: string;
  value: string;
}

export interface PermissionOptions {
  projectPermissions: PermissionItem[];
  assessmentPermissions: PermissionItem[];
  customDataPermissions: PermissionItem[];
  userAndGroupPermissions: PermissionItem[];
  timesheetPermissions: PermissionItem[];
  progressPermissions: PermissionItem[];
  labourPermissions: PermissionItem[];
  exportPermissions: PermissionItem[];
  rolePermissions: PermissionItem[];
  companiesPermissions: PermissionItem[];
  eventTypePermissions: PermissionItem[];
  contractPermissions: PermissionItem[];
}

export const permissionOptions: PermissionOptions = {
  projectPermissions: [
    { name: 'Update Project', value: 'update_project' },
    { name: 'Update Teams', value: 'update_teams' },
    { name: 'Assign Labour to Project', value: 'assign_labour_to_project' },
    { name: 'Download Attachments', value: 'download_attachments' },
    { name: 'View Project Details', value: 'view_project_details' },
    {
      name: 'View Project Assignment Details',
      value: 'view_project_assignment_detail',
    },
    {
      name: 'View Project Assignment Rates',
      value: 'view_project_assignment_rates',
    },
    { name: 'Delete Assignment Role', value: 'delete_assignment_role' },
    { name: 'Generate Project Exports', value: 'generate_project_exports' },
    { name: 'Delete Project Team', value: 'delete_project_team' },
  ],
  assessmentPermissions: [
    { name: 'View Assessments', value: 'view_assessments' },
    { name: 'Update Assessments', value: 'update_assessment_types' },
    { name: 'Send Assessments', value: 'send_assessments' },
    {
      name: 'Delete Assessment Attachments',
      value: 'delete_assessment_attachments',
    },
    {
      name: 'Delete Assessment Submissions',
      value: 'delete_assessment_submissions',
    },
  ],
  customDataPermissions: [
    { name: 'Update Custom Data', value: 'update_custom_data' },
    { name: 'View Custom Data', value: 'view_custom_data' },
    { name: 'Delete Custom Data', value: 'delete_custom_data' },
  ],
  userAndGroupPermissions: [
    { name: 'Assign User to Group', value: 'assign_user_to_group' },
    {
      name: 'Assign Project Filter to User',
      value: 'assign_project_filter_to_user',
    },
    { name: 'Update Group', value: 'update_groups' },
    { name: 'View Users', value: 'view_users' },
  ],
  timesheetPermissions: [
    {
      name: 'Update Timesheet Configuration',
      value: 'update_timesheet_configuration',
    },
    { name: 'Approve Timesheets', value: 'approve_timesheets' },
    { name: 'Unapprove Timesheets', value: 'unapprove_timesheets' },
    { name: 'View Timesheet Submissions', value: 'view_timesheet_submissions' },
    { name: 'View Timesheets', value: 'view_timesheets' },
    { name: 'View Activity Codes', value: 'view_activity_codes' },
    { name: 'Update Activity Codes', value: 'update_activity_codes' },
    { name: 'Delete Activity Codes', value: 'delete_activity_codes' },
  ],
  progressPermissions: [
    { name: 'View Progress Submissions', value: 'view_progress_module' },
    { name: 'View Progress Dashboard', value: 'view_progress_dashboard' },
    { name: 'Save Progress Submission', value: 'save_progress_submission' },
    { name: 'Configure Progress Module', value: 'configure_progress_module' },
    { name: 'Lock Progress Submission', value: 'lock_progress_submission' },
    {
      name: 'Approve Progress Submission',
      value: 'approve_progress_submission',
    },
    { name: 'Generate Progress Reports', value: 'generate_progress_reports' },
    {
      name: 'Delete Progress Activity Codes',
      value: 'delete_progress_activity_codes',
    },
  ],
  labourPermissions: [
    { name: 'Update Labour Profiles', value: 'update_labour_profiles' },
    { name: 'View Labour Profiles', value: 'view_labour_profiles' },
    { name: 'View Skills', value: 'view_skills' },
    { name: 'Update Certifications', value: 'update_certification_types' },
    {
      name: 'Download Certification Attachments',
      value: 'download_certification_attachments',
    },
    {
      name: 'Delete Certification Attachments',
      value: 'delete_certification_attachments',
    },
    { name: 'Delete Certification Types', value: 'delete_certification_types' },
    { name: 'Delete Certifications', value: 'delete_certifications' },
    { name: 'Update Skill Types', value: 'update_skill_types' },
    { name: 'Update Skills', value: 'update_skills' },
  ],
  exportPermissions: [
    {
      name: 'Update Export Configuration',
      value: 'update_export_configuration',
    },
  ],
  rolePermissions: [
    { name: 'Update Roles', value: 'update_roles' },
    { name: 'View Roles', value: 'view_roles' },
    { name: 'View Role Rates', value: 'view_role_rates' },
  ],
  companiesPermissions: [
    { name: 'Delete Company Profiles', value: 'delete_company_profile' },
  ],
  eventTypePermissions: [
    { name: 'View Event Types', value: Permission.ViewEventTypes },
    { name: 'Update Event Types', value: Permission.UpdateEventTypes },
  ],
  contractPermissions: [
    { name: 'View Contract Templates', value: 'view_contract_templates' },
    { name: 'Edit Contract Templates', value: 'edit_contract_templates' },
    { name: 'Send Contracts', value: 'send_contracts' },
    { name: 'View Contract Submissions', value: 'view_contract_submissions' },
  ],
};

export const SaveGroup = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { useLazyQuery, useMutation } = useOrganisationAwareApollo();

  const { activeOrganisation } = useContext(ActiveOrganisationContext);

  const docusealEnabled = activeOrganisation?.docusealEnabled;

  const isUpdate = !!id;

  const { addToast } = useToast();

  const [fetchGroups, { data }] = useLazyQuery(GET_GROUPS, {
    variables: { input: { id } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchGroups({ variables: { input: { id } } });
  }, [id, isUpdate]);

  const [group] = data?.getGroups?.results || [];

  const [saveGroup] = useMutation(SAVE_GROUP);

  const initialValues = id
    ? {
        ...group,
        projectPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.projectPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        assessmentPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.assessmentPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        customDataPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.customDataPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        userAndGroupPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.userAndGroupPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        timesheetPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.timesheetPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        progressPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.progressPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        labourPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.labourPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        exportPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.exportPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        rolePermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.rolePermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        companiesPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.companiesPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        eventTypePermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.eventTypePermissions
            .map((option) => option.value)
            .includes(permission)
        ),
        contractPermissions: group?.permissions.filter((permission: any) =>
          permissionOptions.contractPermissions
            .map((option) => option.value)
            .includes(permission)
        ),
      }
    : {
        projectPermissions: [],
        assessmentPermissions: [],
        customDataPermissions: [],
        userAndGroupPermissions: [],
        timesheetPermissions: [],
        progressPermissions: [],
        labourPermissions: [],
        exportPermissions: [],
        rolePermissions: [],
        companiesPermissions: [],
        eventTypePermissions: [],
        contractPermissions: [],
      };

  const onSubmit = async (
    values: Record<string, any>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      const { data } = await saveGroup({
        variables: {
          id: values?.id,
          input: {
            id: undefined,
            name: values?.name,
            permissions: [
              ...values.projectPermissions,
              ...values.assessmentPermissions,
              ...values.customDataPermissions,
              ...values.userAndGroupPermissions,
              ...values.timesheetPermissions,
              ...values.progressPermissions,
              ...values.labourPermissions,
              ...values.exportPermissions,
              ...values.rolePermissions,
              ...values.companiesPermissions,
              ...values.eventTypePermissions,
              ...values.contractPermissions,
            ],
            updatedAt: undefined,
            createdAt: undefined,
          },
        },
      });
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (data?.saveGroup?.success) {
        addToast('Permissions updated', 'success');
        navigate(-1);
        return;
      } else {
        addToast('Error saving permissions', 'error');
      }
    } catch (error) {
      addToast('Error saving permissions', 'error');
      console.error(error);
    }
  };

  const groupConfig = {
    formSections: [
      {
        title: 'Group',
        fields: [
          {
            id: 'name',
            type: FieldType.input,
            title: 'Name',
          },
          {
            id: 'projectPermissions',
            type: FieldType.checkboxgroup,
            title: 'Project Permissions',
            options: permissionOptions.projectPermissions,
          },
          {
            id: 'assessmentPermissions',
            type: FieldType.checkboxgroup,
            title: 'Assessment Permissions',
            options: permissionOptions.assessmentPermissions,
          },
          {
            id: 'customDataPermissions',
            type: FieldType.checkboxgroup,
            title: 'Custom Data Permissions',
            options: permissionOptions.customDataPermissions,
          },
          {
            id: 'userAndGroupPermissions',
            type: FieldType.checkboxgroup,
            title: 'User & Group Permissions',
            options: permissionOptions.userAndGroupPermissions,
          },
          {
            id: 'timesheetPermissions',
            type: FieldType.checkboxgroup,
            title: 'Timesheet Permissions',
            options: permissionOptions.timesheetPermissions,
          },
          {
            id: 'progressPermissions',
            type: FieldType.checkboxgroup,
            title: 'Progress Permissions',
            options: permissionOptions.progressPermissions,
          },
          {
            id: 'labourPermissions',
            type: FieldType.checkboxgroup,
            title: 'Labour Permissions',
            options: permissionOptions.labourPermissions,
          },
          {
            id: 'exportPermissions',
            type: FieldType.checkboxgroup,
            title: 'Export Permissions',
            options: permissionOptions.exportPermissions,
          },
          {
            id: 'rolePermissions',
            type: FieldType.checkboxgroup,
            title: 'Role Permissions',
            options: permissionOptions.rolePermissions,
          },
          {
            id: 'companiesPermissions',
            type: FieldType.checkboxgroup,
            title: 'Companies Permissions',
            options: permissionOptions.companiesPermissions,
          },
          {
            id: 'eventTypePermissions',
            type: FieldType.checkboxgroup,
            title: 'Event Type Permissions',
            options: permissionOptions.eventTypePermissions,
          },
        ],
      },
    ],
  } as FormConfig;

  // inserting Contract Permissions at second place in fields if docuseal is enabled
  if (docusealEnabled && groupConfig.formSections) {
    groupConfig.formSections[0].fields = [
      ...groupConfig.formSections[0].fields.slice(0, 2),
      {
        id: 'contractPermissions',
        type: FieldType.checkboxgroup,
        title: 'Contract Permissions',
        options: permissionOptions.contractPermissions,
      },
      ...groupConfig.formSections[0].fields.slice(2),
    ];
  }

  const GroupCreationSchema = Yup.object().shape({});

  return (
    <FormCard
      key={`${groupConfig?.title}`}
      config={groupConfig}
      validationSchema={GroupCreationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};
