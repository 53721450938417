import { useMemo, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import ComboBox from '../ComboBox';
import { Button } from '../../Buttons/Button';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import useDebounce from '../../../hooks/useDebounce';
import useCompanies from '../../../hooks/api/companies/useCompanies';

type CompanySelectionProps = {
  title?: string;
  handleCreateNew?: (companyName: string) => void;
  setSelectedCompany: (company: any) => void;
  selectedCompany: any;
  placeholder?: string;
};

export const CompanySelection = ({
  title,
  handleCreateNew,
  setSelectedCompany,
  selectedCompany,
  placeholder = 'Search by company name',
}: CompanySelectionProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedFilterText = useDebounce(searchTerm, 600);

  const filter = useMemo(() => {
    return debouncedFilterText
      ? {
          filters: [
            {
              field: 'companyName',
              stringFilters: [{ ilike: debouncedFilterText }],
            },
          ],
        }
      : null;
  }, [debouncedFilterText]);
  const [companies] = useCompanies({ filter });

  const options = [
    ...(companies?.map((company: any) => ({
      id: company?.id,
      name: company?.companyName,
    })) || []),
  ];

  return (
    <div className="flex flex-col w-full gap-x-4 gap-y-2 pb-2">
      <h2 className="text-sm text-gray-700">{title}</h2>
      <div className="flex flex-col md:flex-row xl:w-1/2 gap-x-2 md:items-center">
        <div className="flex w-full gap-x-2 items-center">
          <ComboBox
            classNames="w-full pb-1"
            testId="company-selection"
            placeholder={placeholder}
            question={{
              id: 'Company Search',
              options,
              onChange: (value) => setSearchTerm(value),
            }}
            handleChange={(id) => {
              if (id) {
                setSelectedCompany(
                  companies?.find((company: any) => company?.id === id)
                );
              } else {
                setSelectedCompany(undefined);
              }
            }}
            value={selectedCompany?.companyName || ''}
            validation={undefined}
          />
          <Button
            className="shadow-none mt-1 -ml-3 px-2 bg-transparent text-black hover:bg-transparent"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setSelectedCompany(null);
            }}
            aria-label="Clear company"
            title="Clear company"
          >
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
        <div>
          {handleCreateNew ? (
            <Button
              className="w-auto text-nowrap"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                handleCreateNew && handleCreateNew(debouncedFilterText);
              }}
              aria-label="Create new company"
              title="Create new company"
            >
              Create New Company
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
