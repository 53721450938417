import { useNavigate } from 'react-router-dom';
import { Button } from './Button';
import { DeleteButton } from '../Deletion/DeleteButton';

interface FormSubmitParams {
  disabled?: boolean;
  entityName?: string;
  isLoading: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit?: () => void;
  showCancel?: boolean;
  submitText: string;
}

const FormSubmit = ({
  disabled,
  entityName,
  isLoading,
  onCancel,
  onDelete,
  onSubmit,
  showCancel = true,
  submitText,
}: FormSubmitParams) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-end gap-x-2 col-span-2">
      {onDelete ? (
        <DeleteButton
          isLoading={isLoading}
          isDisabled={isLoading}
          onDelete={() => onDelete()}
          modalTitle={`Delete ${entityName}`}
          modalText={`Are you sure you want to delete this ${entityName?.toLowerCase()}? You will not be able to restore it once deleted.`}
        >
          Delete
        </DeleteButton>
      ) : null}
      {showCancel ? (
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          style={{ backgroundColor: 'black' }}
          onClick={onCancel ? onCancel : () => navigate(-1)}
          text={'Cancel'}
        />
      ) : null}

      <Button
        isLoading={isLoading}
        isDisabled={isLoading || disabled}
        type="submit"
        onClick={onSubmit}
        text={submitText}
      />
    </div>
  );
};

export default FormSubmit;
